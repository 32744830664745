.orders-wrapper {
	overflow-x: hidden;
	overflow-y: scroll;
	display: flex;
	flex-direction: column;
	height: calc(100% - 4rem);
	// &::-webkit-scrollbar {
	// 	-webkit-appearance: none;
	// }
	// &::-webkit-scrollbar:vertical {
	// 	width: 0px;
	// }
	.filter-wrapper {
		display: flex;
		align-items: center;
		margin-top: 10px;
		margin-bottom: 10px;
		margin-left: 2rem;
		.filter-img {
			width: 26px;
			margin-right: 5px;
		}
		.ant-select {
			margin-right: 10px;
		}
		.ant-select-selection:focus,
		.ant-select-selection:active,
		.ant-select-selection:hover {
			border-color: #000000;
			-webkit-box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2);
			box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2);
		}
	}
	.sub-wrapper {
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: space-between;
		@media screen and (max-width: 1395px) {
			overflow-x: scroll;
			overflow-y: scroll;
		}
		.colWrapper {
			display: flex;
			position: relative;
			cursor: pointer;
		}
		.labelImage {
			position: absolute;
			top: 0;
			left: -0.5px;
		}
		.colImage {
			height: 60px;
			width: 60px;
			object-fit: fill;
		}
		.colDiv {
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding-left: 20px;
		}
		.colText {
			color: #000000;
			margin: 0;
			font-size: 14px;
			font-weight: 600;
			width: 12rem;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
		.colGreyText {
			color: #727272;
			margin: 0;
			font-size: 12px;
			width: 12rem;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
		.textBlack {
			margin: 0;
			font-size: 14px;
			font-weight: 500;
			color: #000000;
		}
		.textCenterBlack {
			margin: 0;
			font-size: 14px;
			font-weight: 500;
			color: #000000;
			text-align: center;
		}
		.textOrange {
			margin: 0;
			font-size: 14px;
			font-weight: 500;
			color: #fe9600;
		}

		.textBlue {
			margin: 0;
			font-size: 14px;
			font-weight: 500;
			color: #0b969b;
		}
		.textDarkBlue {
			margin: 0;
			font-size: 14px;
			font-weight: 500;
			color: #0072d6;
		}
		.textGreen {
			margin: 0;
			font-size: 14px;
			font-weight: 500;
			color: #499b0b;
		}
		.textBrown {
			margin: 0;
			font-size: 14px;
			font-weight: 500;
			color: #d2502d;
		}
		.textRed {
			margin: 0;
			font-size: 14px;
			font-weight: 500;
			color: #9b0b1e;
		}
		.dropDown {
			display: flex;
			align-items: center;
		}
		.artistDiv {
			display: flex;
			align-items: center;
			img {
				height: 11px;
				width: 11px;
				margin-right: 8px;
				margin-bottom: 2px;
			}
		}
		.empty-table {
			display: flex;
			justify-content: center;
			height: calc(100% - 2rem);
			width: 100%;
			align-items: center;
			font-size: 15px;
			color: #000000;
		}

		.ant-table {
			color: #000000;
			font-weight: 500;
			margin-bottom: 40px;
		}
		.ant-table-thead > tr > th {
			padding: 10px 10px 10px 2rem;
			background-color: #f5f5f5;
			color: #000000;
		}

		.ant-table-tbody > tr > td {
			padding: 10px 10px 10px 2rem;
		}
		.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
			background: #e3d2b0;
			box-shadow: 0px 5px 0px 0px #e3d2b0;
		}
		.ant-pagination {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 2rem;
			margin-bottom: 20px;
		}
		.ant-pagination-prev {
			min-width: 22px;
			height: 22px;
			line-height: 22px;
		}
		.ant-pagination-item {
			min-width: 22px;
			height: 22px;
			line-height: 22px;
			border-color: #eeeeee;
			border-radius: 0;
		}
		.ant-pagination-next {
			min-width: 22px;
			height: 22px;
			line-height: 22px;
		}
		.ant-pagination-jump-next {
			min-width: 22px;
			height: 22px;
			line-height: 22px;
		}
		.ant-pagination-jump-prev {
			min-width: 22px;
			height: 22px;
			line-height: 22px;
		}
		.ant-pagination-item a {
			color: #000000;
		}
		.ant-pagination-item:focus a,
		.ant-pagination-item:hover a {
			color: #58514b;
		}
		.ant-pagination-item-active:focus a,
		.ant-pagination-item-active:hover a,
		.ant-pagination-item.ant-pagination-item-active a {
			border-color: #58514b;
			color: #ffffff;
		}
		.ant-pagination-item-active {
			background-color: #58514b;
		}
		.ant-pagination-item:focus,
		.ant-pagination-item:hover {
			border-color: #58514b;
		}
		.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis {
			color: #58514b;
		}
		.anticon {
			color: #58514b;
		}
		.ant-pagination-prev .ant-pagination-item-link {
			border-color: #eeeeee;
			border-radius: 0;
		}
		.ant-pagination-next .ant-pagination-item-link {
			border-color: #eeeeee;
			border-radius: 0;
		}
		.ant-pagination-prev:focus .ant-pagination-item-link,
		.ant-pagination-next:focus .ant-pagination-item-link,
		.ant-pagination-prev:hover .ant-pagination-item-link,
		.ant-pagination-next:hover .ant-pagination-item-link {
			border-color: #58514b;
		}
		.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
		.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
			color: #58514b;
		}
		.circular-loader {
			top: 50%;
			position: absolute;
			left: 56%;
		}
	}
}

.orders-action-popover .ant-popover-inner {
	background-color: #000000;
}

.orders-action-popover.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
	border-top-color: #000000;
	border-left-color: #000000;
}
.action-image {
	height: 25px;
	width: 25px;
	object-fit: contain;
	&:hover {
		cursor: pointer;
	}
}
