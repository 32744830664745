.notification-wrapper {
	overflow-x: hidden;
	overflow-y: scroll;
	display: flex;
	flex-direction: column;
	height: calc(100% - 4rem);
	// margin: 2rem;
	// margin-right: 0;
	// &::-webkit-scrollbar {
	// 	-webkit-appearance: none;
	// }
	// &::-webkit-scrollbar:vertical {
	// 	width: 0px;
	// }
	.notification-card {
		display: flex;
		width: 100%;
		// height: 5rem;
		padding: 15px 0 15px 2rem;
		align-items: center;
		border-bottom: 1px solid #eeeeee;
		.imagebox {
			display: flex;
			border: 0.5px solid #acacac;
			height: 50px;
			width: 4%;
			border-radius: 25px;
			align-items: center;
			justify-content: center;
			position: relative;
			.labelImage {
				position: absolute;
				top: 3px;
				right: -6px;
				background-color: #f60000;
				border-radius: 9px;
				padding: 2px;
				font-size: 10px;
				width: 17px;
				text-align: center;
				color: #ffffff;
				margin: 0;
			}
			.empty-image {
				height: 30px;
				width: 30px;
				object-fit: fill;
			}
			img {
				height: 49px;
				width: 48px;
				border-radius: 25px;
				object-fit: fill;
			}
		}
		.title-wrapper {
			display: flex;
			flex-direction: column;
			padding-left: 20px;
			width: 75%;
			p {
				color: #000000;
				font-size: 14px;
				margin-bottom: 5px;
				font-weight: 500;
			}
			.description-box {
				display: flex;
				color: #000000;
				font-size: 14px;
				p {
					color: #000000;
					font-size: 14px;
					margin-bottom: 5px;
					font-weight: 500;
				}
			}
		}
		.date-wrapper {
			display: flex;
			width: 21%;
			align-items: center;
			justify-content: flex-start;
			p {
				color: #565656;
				font-size: 13px;
				margin-bottom: 5px;
				font-weight: 500;
			}
		}
		:hover {
			cursor: pointer;
		}
	}
	.empty-table {
		display: flex;
		justify-content: center;
		height: calc(100% - 2rem);
		width: 100%;
		align-items: center;
		font-size: 15px;
		color: #000000;
	}
	.circular-loader {
		top: 50%;
		position: absolute;
		left: 56%;
	}
}
