.manage-home-wrapper {
	display: flex;
	height: calc(100% - 4rem);
	.manage-home-list {
		width: 30%;
		min-width: 200px;
		border-right: 1px solid #eee;
		box-shadow: 3px 3px 8px -2px #eee;
		z-index: 11;
		height: 100%;
		hr {
			margin: 0 3%;
		}
	}

	// Empty List
	div.manage-home-list-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 90%;
		font-size: 13px;
		padding-top: 3rem;
		font-weight: 500;
		.circular-loader {
			margin-bottom: 10px;
		}
	}

	ul {
		list-style: none;
		width: 100%;
		margin: 0;
		padding: 0;
		height: 90%;
		overflow-y: scroll;
		&::-webkit-scrollbar {
			-webkit-appearance: none;
		}
		&::-webkit-scrollbar:vertical {
			width: 5px;
		}
		&::-webkit-scrollbar:horizontal {
			height: 0px;
		}
		&::-webkit-scrollbar-thumb {
			background-color: rgb(252, 233, 209);
			border-radius: 5px;
			border: 2px solid transparent !important;
		}
		&::-webkit-scrollbar-track {
			border-radius: 50%;
			background-color: transparent !important;
		}
		li {
			display: flex;
			align-items: center;
			height: 2.5rem;
			color: #000000;
			font-size: 13px;
			font-weight: 500;
			border-left: 3px solid transparent;
			cursor: pointer;
			.list-item-count {
				display: flex;
				align-items: center;
				justify-content: center;
				flex: 2;
				padding: 0 0 0 1rem;
				height: 100%;
			}
			.list-item-content {
				display: flex;
				align-items: center;
				flex: 14;
				padding: 0 0 0 1rem;
				height: 100%;
			}
			&.active {
				background-color: #f8efe3;
				border-left: 3px solid #d2502d;
				transition: all 0.3s, height 0s;
				font-weight: 500;
			}
			&:hover {
				background-color: #f8efe3;
				border-left: 3px solid #d2502d;
				transition: all 0.3s, height 0s;
				font-weight: 500;
				.list-item-action {
					img.more-icon {
						display: none;
					}
					.edit-options {
						display: flex;
						width: 100%;
						height: 100%;
						.image-wrapper {
							display: flex;
							align-items: center;
							justify-content: center;
							flex: 1;
							height: 100%;
							.edit-img {
								width: 18px !important;
							}
							img {
								width: 20px;
							}
							&:hover {
								background-color: rgba(254, 148, 0, 0.199);
							}
						}
					}
				}
			}
			&.head-list-item {
				background-color: #fff;
				border-left: none;
				cursor: default;
			}
		}
	}
	.empty-box {
		display: block;
		text-align: center;
		padding-top: 5%;
		font-size: 13px;
		font-weight: 500;
	}

	hr {
		border-top: 0;
		color: #eee !important;
	}

	.add-button-box {
		display: flex;
		align-items: center;
		font-size: 30px;
		color: #000000;
		font-weight: 500;
		border: 1px solid #565656;
		height: 35px;
		border-radius: 5px;
		box-shadow: 0.25px 0.25px 12px #00000029;
		cursor: pointer;
	}

	.manage-home-view-list {
		width: 70%;
		border-right: 1px solid #eee;
		box-shadow: 3px 3px 8px -2px #eee;
		z-index: 11;
		height: 100%;
		padding: 0 1rem;
		ul li {
			height: 5rem;
			.banner-mob-box {
				display: flex;
				flex: 3;
				padding-left: 1rem;
				align-items: center;
				.image-wrap {
					width: 100%;
					height: 4rem;
				}
				img {
					max-width: 100%;
					max-height: 100%;
					border-radius: 4px;
				}
			}
			.banner-web-box {
				display: flex;
				flex: 4;
				padding-left: 1rem;
				align-items: center;
				.image-wrap {
					width: 100%;
					height: 4rem;
				}
				img {
					max-width: 100%;
					max-height: 100%;
					border-radius: 4px;
				}
			}
			.plain-image-placeholder {
				display: flex;
				align-items: center;
				height: 100%;
				font-size: 11px;
			}
			.product-box {
				display: flex;
				flex: 4;
				padding-left: 1rem;
				.product-details-box {
					width: 100%;
					height: 4rem;
					display: flex;
					align-items: center;
					padding: 7px;
					border: 1px solid #c9c7c7;
					background-color: #f5f5f5;
					border-radius: 5px;
					font-size: 11px;
					font-weight: 400;
					color: #565656;
					.image-wrap {
						display: flex;
						width: 3rem;
						height: 100%;
						padding-right: 6px;
					}
					img {
						max-width: 100%;
						max-height: 100%;
						border-radius: 4px;
					}
					.product-data {
						width: calc(100% - 3rem);
						height: 100%;
						p {
							margin: 0;
							overflow: hidden;
							text-overflow: ellipsis;
						}
						p.desc-text {
							height: calc(100% - 15px);
							overflow: hidden;
							text-overflow: ellipsis;
						}
						p.price-text {
							height: 15px;
						}
					}
					.w-100 {
						width: 100%;
					}
				}
			}
			.filter-box {
				display: flex;
				flex: 2;
				align-items: center;
				justify-content: center;
			}
			.actions-box {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0 0.5rem 0 0rem;
				flex: 2;
				height: 100%;
				img.more-icon {
					display: block;
					width: 22px;
				}
				.edit-options {
					display: none;
				}
			}
			&.active {
				background-color: #f8efe3;
				border-left: 3px solid #d2502d;
				transition: all 0.3s, height 0s;
				font-weight: 500;
				.actions-box {
					img.more-icon {
						display: none;
					}
					.edit-options {
						display: flex;
						width: 100%;
						height: 100%;
						.image-wrapper {
							display: flex;
							align-items: center;
							justify-content: center;
							flex: 1;
							height: 100%;
							img {
								width: 20px;
							}
							&:hover {
								background-color: rgba(254, 148, 0, 0.199);
							}
						}
					}
				}
			}
			&:hover {
				background-color: #f8efe3;
				border-left: 3px solid #d2502d;
				transition: all 0.3s, height 0s;
				font-weight: 500;
				.actions-box {
					img.more-icon {
						display: none;
					}
					.edit-options {
						display: flex;
						width: 100%;
						height: 100%;
						.image-wrapper {
							display: flex;
							align-items: center;
							justify-content: center;
							flex: 1;
							height: 100%;
							img {
								width: 20px;
							}
							&:hover {
								background-color: rgba(254, 148, 0, 0.199);
							}
						}
					}
				}
			}
		}
		hr {
			margin: 0;
		}
		// For Headings
		.manage-home-table-heads {
			font-size: 13px;
			font-weight: 500;
			cursor: default;
			pointer-events: none;
			height: 2.5rem !important;
			&:hover {
				background-color: #fff;
				border-left: 3px solid #fff;
			}
		}
		.circular-loader {
			top: 50%;
			position: absolute;
			left: 56%;
		}
	}
}
