.edit-product-wrapper {
    display: flex;
    justify-content: center;
    height: calc(100% - 4rem);
    overflow-y: scroll;
    padding: 1rem 15%;
    &-box {
        width: 25rem;
        height: 100%;
    }
    .edit-product-subtitle {
        font-weight: 500;
        font-size: 16px;
        border-bottom: 1px solid #bfbfbf;
        color: #d2502d;
        position: relative;
        img {
            position: absolute;
            left: -25px;
            transform: rotate(0deg);
            transition: transform 0.2s linear;
            &.rotate-icon {
                transform: rotate(-90deg);
                transition: transform 0.2s linear;
            }
        }
    }
    .form-control {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 1rem;
        .artwork-image {
            height: 30px;
            width: 30px;
            object-fit: cover;
            margin-right: 5px;
        }
        .category-cascader {
            .ant-input {
                border: 1px solid #cdcdcd;
                border-radius: 3px;
                height: 45px;
                padding: 0px 10px;
                font-size: 14px;
                font-weight: 500;
                color: #323643;
            }
            .ant-cascader-picker-label {
                display: flex;
                align-items: center;
                height: 100%;
                top: 0;
                margin-top: 0;
            }
        }
        .ant-select-selection {
            height: 45px;
            border-color: #cdcdcd;
            font-size: 13px;
            font-weight: 500;
            &:hover {
                border-color: #cdcdcd;
                border-right-width: 1px !important;
            }
            .ant-select-selection__rendered {
                display: flex;
                align-items: center;
                height: 100%;
                ul {
                    height: 100%;
                }
            }
        }
        .ant-select-focused .ant-select-selection,
        .ant-select-selection:focus,
        .ant-select-selection:active {
            border-color: #323643;
            border-width: 2px;
            border-right-width: 2px !important;
            box-shadow: none;
        }
        .tags-select {
            .ant-select-selection {
                height: 3.7rem;
                overflow-y: scroll;
                &::-webkit-scrollbar {
                    -webkit-appearance: none;
                }
            }
        }
        textarea {
            padding: 3px 6px;
            min-height: 70px;
            height: max-content;
        }
        &.form-checkbox {
            flex-direction: row;
            margin-bottom: 1rem;
            .ant-checkbox-inner {
                border-color: #cdcdcd;
            }
            .ant-checkbox-checked .ant-checkbox-inner {
                background-color: #323643;
                border-color: #323643;
            }
            .ant-checkbox-checked::after {
                border: 1px solid #323643;
            }
            .ant-checkbox-wrapper:hover .ant-checkbox-inner,
            .ant-checkbox:hover .ant-checkbox-inner,
            .ant-checkbox-input:focus+.ant-checkbox-inner {
                border-color: #323643;
            }
            .ant-checkbox-checked::after {
                border: 1px solid #323643;
            }
            span {
                font-size: 13px;
                font-weight: 500;
                color: #707070;
            }
        }
        .input-unit-wrapper {
            display: flex;
            input {
                width: 100%;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
            span.unit {
                width: 3rem;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 1rem;
                border: 1px solid #cdcdcd;
                border-left: 0;
                border-radius: 3px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                font-size: 12px;
                font-weight: 500;
                color: #323643;
            }
        }
    }
    .product-iv-title {
        font-size: 14px;
        margin: 1rem 0 5px 3px;
        font-weight: 500;
        color: #707070;
    }
    .product-iv-contents {
        display: flex;
        flex-wrap: wrap;
        .display-hover-screen {
            position: absolute;
            display: none;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: rgba(255, 255, 255, 0.97);
            bottom: 0;
            border-radius: 3px;
            font-size: 13px;
            padding: 0 1rem;
            text-align: center;
            height: 100%;
            width: 100%;
        }
        .product-iv-item {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #cdcdcd;
            border-radius: 5px;
            height: 10rem;
            width: 10rem;
            margin: 5px;
            cursor: pointer;
            img,
            .video-container {
                max-width: 100%;
                max-height: 100%;
                border-radius: 4px;
                margin-top: -1px;
            }
            .close-icon {
                position: absolute;
                top: -7px;
                right: -7px;
            }
            .download-icon {
                font-size: 27px;
                padding: 15px;
                background-color: rgba(218, 218, 218, 0.56);
                border-radius: 50%;
            }
            .edit-icon {
                margin-left: 5px;
                font-size: 27px;
                padding: 15px;
                background-color: rgba(218, 218, 218, 0.56);
                border-radius: 50%;
            }
            &:hover {
                .display-hover-screen {
                    display: flex;
                    .download-icon {
                        animation: ZoomInKey 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
                    }
                    @keyframes ZoomInKey {
                        0% {
                            transform: scale(0.5);
                        }
                        100% {
                            transform: scale(1);
                        }
                    }
                }
            }
        }
    }
    .form-control-array {
        width: 100%;
        padding: 1rem 1rem 10px;
        border: 1px solid #cdcdcd;
        border-radius: 3px;
        margin-bottom: 1rem;
        label {
            display: block;
            margin-bottom: 10px;
        }
        .button-wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            font-size: 13px;
            font-weight: 500;
            color: #707070;
            img {
                height: 40px;
                width: 40px;
                margin-bottom: -6px;
                cursor: pointer;
            }
        }
    }
    .mandatory {
        color: #ff0000;
    }
    .display-hover-screen {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: rgba(255, 255, 255, 0.97);
        bottom: 0;
        border-radius: 3px;
        font-size: 13px;
        padding: 0 1rem;
        text-align: center;
        height: 100%;
        width: 100%;
    }
    .edit-product-button-container {
        display: flex;
        justify-content: flex-end;
        margin: 2rem .5rem;
        button {
            height: 50px;
            border: none;
            background-color: #58514b;
            color: #fff;
            font-size: 14px;
            font-weight: 500;
            outline: none;
            padding: 3px 2rem;
            border-radius: 3px;
            width: 100%;
            margin-left: 5px;
            &:disabled {
                background-color: rgba(218, 218, 218, 0.56);
            }
        }
    }
}

.video-edit-delete-btn-container {
    display: flex;
    flex-direction: row;
    margin: 15px 0px;
    span {
        text-decoration: underline;
        font-size: 16px;
        color: red;
        cursor: pointer;
        margin-right: 20px;
    }
}

.disabledbutton {
    pointer-events: none;
    opacity: 0.4;
}