.managegallery-wrapper {
  // overflow-x: hidden;
  // overflow-y: scroll;
  display: flex;
  flex-direction: column;
  //justify-content: space-between;
  height: calc(100% - 4rem);
  // &::-webkit-scrollbar {
  // 	-webkit-appearance: none;
  // }
  // &::-webkit-scrollbar:vertical {
  // 	width: 0px;
  // }
  .filter-wrapper {
    display: flex;
    align-items: center;
    margin: 10px;
    gap: 5px;

    .filter-field {
      flex: 1;
      width: 200px;

      .ant-select-selection {
        height: 45px !important;
      }
      .ant-select-selection__rendered {
        line-height: 45px;
      }
    }
    button {
      flex: 1;
      height: 45px;
      margin-bottom: 0px;
      .login-progress {
        position: absolute;
        left: 30%;
        color: #ffffff;
      }
    }

    .country-drpdwn {
      flex: 1;

      .multiselect-container {
        margin-left: 0 !important;
      }
    }
  }

  .sub-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    overflow-x: auto;
    overflow-y: auto;

    .colWrapper {
      display: flex;
      align-items: center;
      .colImage {
        height: 27px;
        width: 27px;
        object-fit: fill;
      }
      .colText {
        color: #000000;
        margin: 0;
        font-size: 14px;
        font-weight: 400;
        padding-left: 20px;
        width: 12rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .icon-image {
        height: 27px;
        width: 27px;
        border: 1px solid #5a5a5a;
        font-size: 21px;
        padding: 2px;
        color: #1a1818;
      }
    }
    .star-image {
      margin-left: 18px;
      height: 15px;
      width: 15px;
    }
    .artworkcountText {
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 600;
      color: #000000;
      text-align: center;
      margin-right: 3rem;
    }
    .textBlack {
      margin: 0;
      font-size: 14px;
      font-weight: 400;
      color: #000000;
    }
    .textOrange {
      margin: 0;
      font-size: 14px;
      font-weight: 400;
      color: #fe9600;
    }

    .textBlue {
      margin: 0;
      font-size: 14px;
      font-weight: 400;
      color: #0b969b;
    }
    .textDarkBlue {
      margin: 0;
      font-size: 14px;
      font-weight: 400;
      color: #0072d6;
    }
    .textGreen {
      margin: 0;
      font-size: 14px;
      font-weight: 400;
      color: #499b0b;
    }
    .textBrown {
      margin: 0;
      font-size: 14px;
      font-weight: 400;
      color: #d2502d;
    }
    .textRed {
      margin: 0;
      font-size: 14px;
      font-weight: 400;
      color: #9b0b1e;
    }
    .empty-table {
      display: flex;
      justify-content: center;
      height: calc(100% - 2rem);
      width: 100%;
      align-items: center;
      font-size: 15px;
      color: #000000;
    }
    .sorter-no-tooltip .ant-table-column-sorters:before {
      content: none !important;
    }
    .ant-select {
      margin-right: 10px;
    }
    .ant-select-selection:focus,
    .ant-select-selection:active,
    .ant-select-selection:hover {
      border-color: #000000;
      -webkit-box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2);
      box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2);
    }
    .ant-table {
      color: #000000;
      font-weight: 400;
      margin-bottom: 20px;
      // @media screen and (max-width: 1395px) {
      //   overflow-x: scroll;
      //   overflow-y: scroll;
      // }
    }
    .ant-table-thead > tr > th {
      padding: 10px 10px 10px 2.2rem;
      background-color: #f5f5f5;
      color: #000000;
      .ant-table-column-sorter
        .ant-table-column-sorter-inner
        .ant-table-column-sorter-up {
        font-size: 14px !important;
      }
      .ant-table-column-sorter
        .ant-table-column-sorter-inner
        .ant-table-column-sorter-down {
        font-size: 14px !important;
      }
    }
    .ant-table-tbody > tr {
      cursor: pointer;
      border-left: 4px solid #ffffff;
      &:hover {
        border-left: 4px solid #d2502d;
      }
    }
    .ant-table-tbody > tr > td {
      padding: 10px 10px 10px 2rem;
      border-bottom: none;
    }
    .ant-table-tbody
      > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
      > td {
      background: #e3d2b0;
    }
  }
  .ant-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    margin-bottom: 20px;
  }
  .ant-pagination-prev {
    min-width: 22px;
    height: 22px;
    line-height: 22px;
  }
  .ant-pagination-item {
    min-width: 22px;
    height: 22px;
    line-height: 22px;
    border-color: #eeeeee;
    border-radius: 0;
  }
  .ant-pagination-next {
    min-width: 22px;
    height: 22px;
    line-height: 22px;
  }
  .ant-pagination-jump-next {
    min-width: 22px;
    height: 22px;
    line-height: 22px;
  }
  .ant-pagination-jump-prev {
    min-width: 22px;
    height: 22px;
    line-height: 22px;
  }
  .ant-pagination-item a {
    color: #000000;
  }
  .ant-pagination-item:focus a,
  .ant-pagination-item:hover a {
    color: #58514b;
  }
  .ant-pagination-item-active:focus a,
  .ant-pagination-item-active:hover a,
  .ant-pagination-item.ant-pagination-item-active a {
    border-color: #58514b;
    color: #ffffff;
  }
  .ant-pagination-item-active {
    background-color: #58514b;
  }
  .ant-pagination-item:focus,
  .ant-pagination-item:hover {
    border-color: #58514b;
  }
  .ant-pagination-jump-prev
    .ant-pagination-item-container
    .ant-pagination-item-ellipsis {
    color: #58514b;
  }
  .anticon {
    color: #58514b;
  }
  .ant-pagination-prev .ant-pagination-item-link {
    border-color: #eeeeee;
    border-radius: 0;
  }
  .ant-pagination-next .ant-pagination-item-link {
    border-color: #eeeeee;
    border-radius: 0;
  }
  .ant-pagination-prev:focus .ant-pagination-item-link,
  .ant-pagination-next:focus .ant-pagination-item-link,
  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link {
    border-color: #58514b;
  }
  .ant-pagination-jump-prev
    .ant-pagination-item-container
    .ant-pagination-item-link-icon,
  .ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-link-icon {
    color: #58514b;
  }
  .circular-loader {
    top: 50%;
    position: absolute;
    left: 56%;
  }
}

.manage-gallery-action-popover .ant-popover-inner {
  background-color: #000000;
}

.manage-gallery-action-popover.ant-popover-placement-bottom
  > .ant-popover-content
  > .ant-popover-arrow {
  border-top-color: #000000;
  border-left-color: #000000;
}
.action-image {
  height: 25px;
  width: 25px;
  object-fit: contain;
  margin-left: 10px;
  &:hover {
    cursor: pointer;
  }
}
