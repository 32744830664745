.add-brands-dialog {
    .dialog-title {
        margin: 0 !important;
        background-color: #F5F5F5;
        border-bottom: 1px solid #EEEEEE;
        h2 {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
        }
    }
    .add-brands-checkbox {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-checkbox-inner {
        border-color: #d9d9d9;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #FE9600;
        border-color: #FE9600;
    }
    .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: #FE9600;
    }
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
        -webkit-appearance: none; 
        margin: 0; 
    }
    .add-brands-content {
        display: flex;
        flex-direction: column;
        padding: 0;
        font-size: 15px;
        
        .add-brands-body {
            height: 100%;
            overflow-y: scroll;
            &::-webkit-scrollbar {
                -webkit-appearance: none;
            }
            &::-webkit-scrollbar:vertical {
                width: 1px;
            }
            &::-webkit-scrollbar:horizontal {
                height: 0px;
            }
            &::-webkit-scrollbar-thumb {
                background-color: #C4C9CA;
                border-radius: 0px;
                border: 2px solid transparent !important;
            }
            &::-webkit-scrollbar-track {
                border-radius: 50%;
                background-color: transparent !important;
            }
        }

        // Slider Details
        .add-brands-body-top {
            display: flex;
            width: 100%;
            margin: 0.5rem 0;
            justify-content: center;
            height: 7rem;
            .form-control-image-box {
                margin-right: 2rem;
                .upload-box {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    width: 7rem;
                    height: 7rem;
                    background-color: #FE9600;
                    border: 1px solid #CDCDCD;
                    border-radius: 3px;
                    font-size: 11px;
                    color: #FFF;
                    font-weight: 500;
                    cursor: pointer;
                    img.image-upload {
                        margin-bottom: 4px;
                        width: 23px;
                        height: 23px;
                    }
                    img.media-thumb {
                        max-width: 100%;
                        max-height: 100%;
                    }
                    label {
                        text-align: center
                    }
                }
                .bg-white {
                    background-color: #FFF;
                }
            }
            .add-brands-checkbox {
                flex-direction: column;
                padding: 0.6rem 1rem 0;
                label {
                    margin-top: 0.5rem;
                }
            }
            .form-control {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 15rem;
                input {
                    height: 40px;
                    width: 100%;
                }
            }
        }
        .add-brands-body-bottom {
            min-height: calc(100% - 8rem);
            background-color: #F5F5F5;
            padding: 1rem 7%;
            .sub-title {
                color: #000000;
                font-size: 15px;
                font-weight: 700;
                margin-bottom: 1rem;
            }
            .psuedo-inputs {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                .psuedo-input {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border: 1px solid #CDCDCD;
                    background-color: rgb(251, 251, 251);
                    border-radius: 3px;
                    height: 31px;
                    margin-bottom: 1rem;
                    margin-right: 1rem;
                    padding: 0px 10px;
                    min-width: 9rem;
                    cursor: default;
                    p {
                        margin: 0;
                        font-size: 14px;
                        text-transform: capitalize;
                    }
                    img {
                        cursor: pointer;
                        width: 18px;
                    }
                }
            }
        }
    }
    .add-brands-action {
        display: flex;
        width: 100%;
        justify-content: center;
        width: 16rem;
        button {
            width: 49%;
            height: 2rem;
            &:first-of-type {
                margin-right: 2%;
            }
        }
        .modal-button-loader {
            position: absolute;
            left: 70%;
        }
    }
    .modal-button-loader {
        position: absolute;
        left: 70%;
    }
}

.ant-tooltip {
    z-index: 1500 !important;
}