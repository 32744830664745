#add-auction-lot-id {
  overflow-y: scroll;
  //   display: flex;
  //   flex-direction: column;
  height: calc(100% - 2rem);
  // margin: 2rem 8rem;
  justify-content: center;
  // background: #ffffff;
  // box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.18);
  // border-radius: 8px;
  padding: 1rem 5rem;

  .flx-grw-row {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
  }

  .save-lot-btn {
    justify-content: end;
    gap: 1rem;
    margin: 2rem;
  }

  input {
    background-color: #f8f8f8;
  }

  .add-lot-id {
    background: #ffffff;
    box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.18);
    border-radius: 8px;
    padding: 1rem 5rem;
    .form-control {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-bottom: 1.5rem;
    }
  }

  .searchButton {
    background-color: black;
    color: #ffffff;
  }

  .auction-search-artwork {
    background: #ffffff;
    box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.18);
    border-radius: 8px;
    padding: 1rem 5rem;
    margin-top: 3rem;

    .gutter-box {
      padding: 8px 0;
      background: #00a0e9;
    }
    .ant-popover-content {
      width: 30rem !important;
    }
  }

  .form-date-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .form-control-date {
    width: 45%;
    display: flex;
    flex-direction: column;
    padding-bottom: 1.5rem;
  }

  .search-artwork-result {
    background: #ffffff;
    box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.18);
    border-radius: 8px;
    padding: 3rem 3rem;
    margin: 3rem 0;

    .filter-artworks-wrapper {
      display: flex;
      flex-wrap: wrap;
      // justify-content: center;
    }
  }

  .ant-pagination-item-active:focus a,
  .ant-pagination-item-active:hover a,
  .ant-pagination-item.ant-pagination-item-active a {
    color: #000000;
  }
}
