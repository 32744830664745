.topbar-wrapper {
	display: flex;
	justify-content: space-between;
	width: 100%;
	height: 4rem;
	border-bottom: 1px solid #6e6e6e;
	padding: 0 2rem;

	.topbar-title {
		display: flex;
		align-items: center;
		color: #d2502d;
		font-size: 15px;
		font-weight: 500;
		.breadCrumb-separator {
			width: 10px;
		}
		.ant-breadcrumb a {
			color: #d2502d;
			font-size: 15px;
			font-weight: 500;
			&:hover {
				text-decoration: underline;
			}
		}
	}

	.topbar-filter {
		display: flex;
		justify-content: center;
		align-items: center;
		.filter-img {
			width: 26px;
			margin-right: 5px;
		}
		p {
			font-size: 11px;
			color: #000000;
			font-weight: 700;
			margin: 0;
		}
		.filter-field {
			width: 150px;
			margin-left: 10px;
			margin-right: 10px;
			.ant-select-selection {
				box-shadow: none !important;
				border-color: #eee;
				font-size: 13px;
				height: 32px;
				&:active,
				&:focus {
					border: 2px solid #fe9600;
					border-right-width: 3px !important;
				}
			}
		}
	}

	.topbar-brand-filter {
		display: flex;
		align-items: center;
		padding-right: 12rem;
		.filter-img {
			width: 24px;
			margin-right: 5px;
		}
		p {
			font-size: 12px;
			color: #000000;
			margin-bottom: 0;
			width: 50px;
			font-weight: 500;
		}
		.category-filter {
			display: flex;
			height: 33px;
			width: 190px;
			border: 1px solid #f2f2f2;
			border-radius: 3px;
			justify-content: space-between;
			cursor: pointer;
			margin-left: 10px;
			p {
				font-size: 16px;
				color: #000000;
				font-weight: 500;
				margin-bottom: 0;
				padding-left: 10px;
				padding-top: 5px;
			}
		}
		.filter-field {
			width: 100px;
		}
	}

	.topbar-search {
		display: flex;
		align-items: center;
		input {
			border: 1px solid #eee;
			border-radius: 4px;
			width: 250px;
			padding: 5px 20px 5px 10px;
			background-image: url(../../assets/images/common/Search.svg);
			background-repeat: no-repeat;
			background-position: 98% 50%;
			background-size: 22px;
			&:active,
			&:focus {
				border: 2px solid #58514b;
			}
		}
	}
	.category-modal {
		display: flex;
		width: 825px;
		height: 450px;
		position: absolute;
		z-index: 2000;
		background-color: #fff;
		margin-top: 3.5rem;
		border: 1px solid #c2c2c2;
		border-radius: 4px;
		margin-left: 12rem;

		&-body {
			display: flex;
			padding: 30px;
			width: 100%;
			.main-category {
				display: flex;
				flex-direction: column;
				width: 35%;
				color: #fe9600;
				font-size: 18px;
				font-weight: 500;
				border-right: 1px solid #d9d9d9;
				p {
					color: #000000;
					font-size: 16px;
					font-weight: 500;
					margin: 0;
					padding-top: 15px;
				}
			}
			.sub-category {
				display: flex;
				flex-direction: column;
				width: 35%;
				color: #fe9600;
				font-size: 18px;
				font-weight: 500;
				border-right: 1px solid #d9d9d9;
				padding-left: 20px;
				p {
					color: #000000;
					font-size: 16px;
					font-weight: 500;
					margin: 0;
					padding-top: 15px;
				}
			}
			.sub-category-last {
				display: flex;
				flex-direction: column;
				width: 30%;
				color: #fe9600;
				font-size: 18px;
				font-weight: 500;
				padding-left: 20px;
				p {
					color: #000000;
					font-size: 16px;
					font-weight: 500;
					margin: 0;
					padding-top: 15px;
				}
			}
		}
	}
	.priority-modal {
		display: flex;
		flex-direction: column;
		width: 240px;
		height: 170px;
		position: absolute;
		z-index: 2000;
		background-color: #fff;
		margin-top: 3.5rem;
		border: 1px solid #c2c2c2;
		border-radius: 4px;
		margin-left: 30.3rem;
		.priority-checks {
			display: flex;
			width: 82%;
			padding-top: 15px;
			height: 40px;
			margin-left: 22px;
			border-bottom: 1px solid #f1f1f1;

			input {
				//-webkit-appearance: initial;
				appearance: initial;
				background-color: #fff;
				width: 17px;
				height: 17px;
				border: 1px solid #707070;
				position: relative;
				border-radius: 4px;
				margin-top: 2px;
				cursor: pointer;
				&:checked {
					background-color: #fe9600;
				}
			}
			p {
				color: #000000;
				font-size: 15px;
				font-weight: 500;
				padding-left: 10px;
			}
		}
		.priority-Normal {
			display: flex;
			width: 82%;
			padding-top: 15px;
			height: 40px;
			margin-left: 22px;

			input {
				//-webkit-appearance: initial;
				appearance: initial;
				background-color: #fff;
				width: 17px;
				height: 17px;
				border: 1px solid #707070;
				position: relative;
				border-radius: 4px;
				margin-top: 2px;
				cursor: pointer;
				&:checked {
					background-color: #fe9600;
				}
			}
			p {
				color: #000000;
				font-size: 15px;
				font-weight: 500;
				padding-left: 10px;
			}
		}
	}
}

.ant-select-dropdown-menu-item:active:not(.ant-select-dropdown-menu-item-disabled) {
	background-color: #e5e5e5 !important;
}
.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
	background-color: #e5e5e5 !important;
}
.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
	background-color: #e5e5e5 !important;
}
.ant-select-dropdown-menu {
	.artwork-image {
		height: 30px;
		width: 30px;
		object-fit: cover;
		margin-right: 5px;
	}
}
