.artwork-card-image-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
}

.artwork-card-image {
  position: relative;
  height: 165px;
  max-width: 230px;
  cursor: pointer;
  &.isSelected {
    opacity: 0.5;
  }
}

.artwork-card-selection {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .anticon {
    font-size: 37px;
    animation: scale-up-center 0.1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
  @keyframes scale-up-center {
    0% {
      transform: scale(0.5);
    }
    100% {
      transform: scale(1);
    }
  }
}

.artwork-card-title {
  font-size: 12px;
  font-weight: 500;
  margin-top: 6px;
  margin-bottom: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.artwork-card-actions {
  position: absolute;
  display: flex;
  height: 26px;
  align-items: center;
  background-color: #fff;
  border-radius: 2rem;
  right: 8px;
  bottom: 8px;
  padding: 0 5px;
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.45);
  img {
    width: 33px;
    padding: 0 8.5px;
    border-right: 1px solid #bfbfbf;
    cursor: pointer;
    &:last-of-type {
      border: none;
    }
  }
}

.artwork-card-footer {
  display: flex;
  justify-content: space-between;
}

.artwork-card-artist {
  display: flex;
  align-items: center;
  img {
    width: 13px;
    height: 10px;
    margin-right: 1px;
    position: relative;
    left: -3px;
  }
  p {
    font-size: 13px;
    color: #000000;
    font-weight: 600;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 180px;
  }
}

.artwork-card-price {
  font-size: 13px;
  color: #000;
  font-weight: 700;
  margin-bottom: 0;
  margin-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100px;
}

.artwork-card-wrapper {
  max-width: 385px;
  cursor: default;
  margin: 10px;
}
