.confirm-popup-overlay .ant-popover-inner-content {
  padding: 6px 9px;
}
.confirm-popup-overlay .ant-popover-message {
  display: flex;
  align-items: center;
  justify-content: center;
}
.confirm-popup-overlay .ant-popover-message img {
  width: 15px;
}
.confirm-popup-overlay .ant-popover-message-title {
  padding-left: 10px;
}
.confirm-popup-overlay .ant-btn-primary {
  color: #fff;
  background-color: #58514b;
  border-color: #58514b;
}
.confirm-popup-overlay .ant-btn {
  cursor: pointer;
  border-radius: 0;
  font-size: 13px;
  height: 30px;
  padding: 0 2rem;
  font-weight: 500;
}
.confirm-popup-overlay .ant-popover-buttons {
  margin: 0;
  display: flex;
}
.confirm-popup-overlay .ant-popover-buttons button:first-of-type {
  margin: 0;
}
