.order-status-wrapper {
	overflow-x: hidden;
	overflow-y: scroll;
	display: flex;
	flex-direction: column;
	height: calc(100% - 4rem);
	// &::-webkit-scrollbar {
	// 	-webkit-appearance: none;
	// }
	// &::-webkit-scrollbar:vertical {
	// 	width: 0px;
	// }
	.head-wrapper {
		display: flex;
		.detail-wrapper {
			display: flex;
			width: 100%;
			align-items: center;
			padding-left: 2rem;
			color: #000000;
			font-weight: 500;
			font-size: 16px;
			background-color: #f5f5f5;
			width: 35%;
		}
		.form-grid {
			display: flex;
			justify-content: space-between;
			width: 65%;
			.detail-wrapper {
				display: flex;
				height: 40px;
				width: 40%;
				align-items: center;
				padding-left: 20px;
				color: #000000;
				font-weight: 500;
				font-size: 16px;
				background-color: #f5f5f5;
			}
			.message-wrapper {
				display: flex;
				height: 40px;
				width: 60%;
				align-items: center;
				padding-left: 20px;
				color: #000000;
				font-weight: 500;
				font-size: 16px;
				background-color: #cfcfcf;
			}
		}
	}
	.content-wrapper {
		display: flex;
		justify-content: space-between;
		.colum-grid {
			display: flex;
			flex-direction: column;
			height: 100%;
			border-right: 1px solid #f5f5f5;
			width: 35%;
			padding: 0 2rem 2rem 2rem;
			.list-wrapper {
				display: flex;
				flex-direction: column;

				.sub-wrapper {
					display: flex;
					border-bottom: 1px solid #f5f5f5;
					padding-top: 1rem;
					ul {
						list-style-type: none;
						padding-left: 0;
						li {
							color: #565656;
							font-size: 14px;
							font-weight: 500;
							padding-bottom: 10px;
						}
						.greylist {
							color: #565656;
							font-size: 14px;
							font-weight: 500;
							padding-bottom: 10px;
						}
					}
					.detail-values {
						list-style-type: none;
						padding-left: 15px;
						li {
							color: #000000;
							font-size: 14px;
							font-weight: 600;
							padding-bottom: 10px;
							img {
								height: 90px;
								width: 90px;
								object-fit: cover;
								margin-left: 2.5rem;
							}
							h2 {
								color: #000000;
								font-size: 14px;
								font-weight: 600;
								margin: 0;
								padding-top: 10px;
								padding-bottom: 10px;
								padding-left: 2.5rem;
							}
							.description {
								margin: 0;
								color: #000000;
								font-size: 13px;
								font-weight: 400;
								padding-left: 2.5rem;
							}
							.artist-name {
								color: #1a1718;
								font-size: 14px;
								font-weight: 500;
							}
							.addressform {
								display: flex;
								flex-direction: column;
								.nameText {
									padding-bottom: 10px;
									color: #000000;
									font-size: 16px;
									font-weight: 900;
									margin: 0;
								}
								.addressData {
									color: #000000;
									font-size: 13px;
									font-weight: 400;
									margin: 0;
								}
							}
						}
					}
				}
				.end-wrapper {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					padding-top: 2rem;
					.first-imagebutton {
						display: flex;
						justify-content: center;
						align-items: center;
						background-color: #f2f2f2;
						height: 35px;
						width: 150px;
						cursor: pointer;
						margin-bottom: 10px;
						.first-iconimage {
							margin-right: 10px;
							height: 18px;
							width: 18px;
						}
						.title {
							font-weight: 500;
							font-size: 14px;
							color: #000000;
							margin: 0;
						}
					}
					.second-imagebutton {
						display: flex;
						justify-content: center;
						align-items: center;
						background-color: #f2f2f2;
						height: 35px;
						width: 150px;
						cursor: pointer;
						.second-iconimage {
							margin-right: 10px;
							height: 25px;
							width: 25px;
						}
						.title {
							font-weight: 500;
							font-size: 14px;
							color: #000000;
							margin: 0;
						}
					}
				}
			}
		}
		.colum-grid-new {
			display: flex;
			flex-direction: column;
			height: 100%;
			width: 65%;
			.track-wrapper {
				display: flex;
				justify-content: space-between;
				width: 100%;
				height: 40%;
				.tracking {
					display: flex;
					width: 40%;
				}
				.track-details {
					display: flex;
					flex-direction: column;
					width: 60%;
					padding-left: 20px;
					.status-head {
						color: #d2502d;
						font-size: 14px;
						font-weight: 500;
						padding-top: 30px;
					}
					.status-date {
						color: #000000;
						font-size: 12px;
						padding-top: 10px;
						padding-bottom: 10px;
						font-weight: 500;
					}
					.status-data {
						color: #585858;
						font-size: 12px;
					}
				}
			}
			.pickup-wrapper {
				display: flex;
				flex-direction: column;
				border-bottom: 1px solid #f5f5f5;
				padding-left: 1rem;
				margin-bottom: 10px;
				.sub-wrapper {
					display: flex;
					ul {
						list-style-type: none;
						padding-left: 0;
						li {
							color: #565656;
							font-size: 14px;
							font-weight: 500;
							padding-bottom: 10px;
						}
						.greylist {
							color: #565656;
							font-size: 14px;
							font-weight: 500;
							padding-bottom: 10px;
						}
					}
					.detail-values {
						list-style-type: none;
						padding-left: 15px;
						li {
							color: #000000;
							font-size: 14px;
							font-weight: 600;
							padding-bottom: 10px;
						}
					}
				}
			}
			.status-wrapper {
				display: flex;
				background-color: #f5f5f5;
				justify-content: space-around;
				align-items: center;
				height: 150px;
				.form-status {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: flex-start;
					height: 100%;
					.selection-title {
						color: #707070;
						font-size: 14px;
						padding-bottom: 10px;
						margin: 0;
					}
				}
				.note-status {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: flex-start;
					width: 50%;
					.selection-title {
						color: #707070;
						font-size: 14px;
						padding-bottom: 10px;
						margin: 0;
					}
					.noteText {
						width: 100%;
						border: 1px solid #cdcdcd;
						border-radius: 3px;
						height: 33px;
						margin-bottom: 10px;
						font-size: 14px;
						outline: none;
						&:focus {
							border-color: #000000;
							outline: none;
						}
					}
				}
				.track-btn {
					height: 33px;
					background-color: #58514b;
					border: 0.5px solid #707070;
					color: #ffffff;
					font-size: 14px;
					font-weight: 500;
					width: 100px;
					outline: none;
					cursor: pointer;
					margin-top: 20px;
					&:hover {
						color: #f2d6a0;
						outline: none;
					}
				}
			}
		}
	}
	.ant-steps {
		width: 100%;
		padding: 20px;
	}
	.ant-steps-vertical > .ant-steps-item > .ant-steps-item-tail::after {
		width: 1.5px;
		height: 131%;
	}
	.ant-steps-vertical.ant-steps-dot .ant-steps-item-tail {
		top: 1px;
		left: -9px;
		margin: 0;
		padding: 14px 0 4px;
	}
	.ant-steps-item-finish > .ant-steps-item-tail::after {
		background-color: #ad9972;
	}
	.ant-steps-dot .ant-steps-item-content {
		width: 85%;
	}
	.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
		background: #d2502d;
	}
	.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
		background-color: #ad9972;
	}
	.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
		background: #ad9972;
	}
	.ant-steps-item-title {
		font-size: 14px;
	}
	.ant-steps-item-description {
		font-size: 12px;
	}
	.ant-steps-item-process > .ant-steps-item-content > .ant-steps-item-title {
		color: #d2502d;
	}
	.ant-steps-item-finish > .ant-steps-item-content > .ant-steps-item-title {
		color: #000000;
		font-weight: 500;
	}
	.ant-steps-item-wait > .ant-steps-item-content > .ant-steps-item-title {
		font-weight: 500;
	}
	.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role="button"]:hover .ant-steps-item-title {
		color: #af935a;
	}
	.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role="button"]:hover .ant-steps-item-description {
		color: #af935a;
	}
	.ant-select {
		margin-bottom: 10px;
		width: 100%;
	}
	.ant-select-selection {
		background-color: #dec390;
	}
	.ant-select-selection:focus,
	.ant-select-selection:active,
	.ant-select-selection:hover {
		border-color: #000000;
		-webkit-box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2);
		box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2);
	}
	.ant-select-selection__placeholder {
		color: #58514b;
	}
	.empty-table {
		display: flex;
		justify-content: center;
		height: calc(100% - 2rem);
		width: 100%;
		align-items: center;
		font-size: 15px;
		color: #000000;
	}
	.circular-loader {
		top: 50%;
		position: absolute;
		left: 56%;
	}
}
