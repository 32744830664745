@import url('https://fonts.googleapis.com/css?family=Montserrat:200,300,400&display=swap');
.message-container-wrapper {
    height: 100%;
    width: 100%;
    #notfound {
        position: relative;
        height: 100vh;
        display: flex;
        justify-content: center;
        .logo-box {
            padding-top: 4rem;
            .logo-icon {
                margin-right: 10px;
            }
        }
    }
    .notfound {
        max-width: 520px;
        width: 100%;
        line-height: 1.4;
        text-align: center;
    }
    #notfound .notfound {
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
    }
    .notfound .notfound-404 {
        position: relative;
        height: 200px;
        margin: 0 auto 20px;
        z-index: -1;
    }
    .notfound .notfound-404 h1 {
        font-family: 'Montserrat', sans-serif;
        font-size: 236px;
        font-weight: 200;
        margin: 0;
        color: #211b19;
        text-transform: uppercase;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
    }
    .notfound .notfound-404 h2 {
        font-family: 'Montserrat', sans-serif;
        font-size: 28px;
        font-weight: 400;
        text-transform: uppercase;
        color: #211b19;
        background: #fff;
        padding: 10px 5px;
        margin: auto;
        display: inline-block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .notfound a {
        font-family: montserrat,sans-serif;
        display: inline-block;
        font-weight: 700;
        text-decoration: none;
        color: #fff;
        text-transform: uppercase;
        padding: 13px 23px;
        background: #FE9600;
        font-size: 18px;
        -webkit-transition: .2s all;
        transition: .2s all;
        box-shadow: 3px 4px 10px 2px #EEE;
        cursor: pointer;
    }
    @media (max-width: 768px) {
        .notfound .notfound-404 h1 {
            font-size: 14vh;
        }
        .notfound .notfound-404 h2 {
            font-size: 2vh;
            top: 19vh;
            bottom: auto;
            width: 36vh;
        }
    }
}