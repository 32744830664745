.new-survey-main-wrapper {
    overflow-y: scroll;
    height: 100vh;
    justify-content: center;
    padding: 1rem 1rem;
    .form-control {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-bottom: 1.5rem;
        margin-right: 15px;
        .ant-checkbox-wrapper {
            margin-top: 30px;
        }
        .choice-add-btn {
            background-color: #000;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 10px;
            width: 35px;
            height: 33px;
            border: solid;
            font-weight: bolder;
            border-radius: 5px;
            cursor: pointer;
        }
    }
    .form-row {
        display: flex;
        flex-direction: row;
        .choice-list-ul {
            li {
                font-size: 16px;
                font-weight: 500;
                display: flex;
                flex-direction: row;
                span {
                    color: rgb(212, 0, 0);
                    height: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 5px;
                    margin: 1px 10px;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
    }
    .btn-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 15px;
        div {
            background-color: #000;
            height: 45px;
            width: 200px;
            color: #fff;
            font-size: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 5px;
            cursor: pointer;
        }
    }
    .add-question-btn-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .add-question-btn {
            height: 45px;
            width: 200px;
            background-color: #000;
            color: #fff;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 700;
            cursor: pointer;
        }
    }
    .survey-error-msg {
        color: red;
    }
}

.survey-main-wrapper {
    .ant-pagination-item-active {
        background-color: #000;
    }
}

.survey-table-container {
    padding: 1rem 0px;
    width: 40%;
}

.preview-link-wrapper {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    span {
        background-color: rgb(8, 106, 119);
        color: #fff;
        padding: 10px;
        font-size: 16px;
        cursor: pointer;
        border-radius: 5px;
    }
}

.question-main-wrapper-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.common-question-wrapper {
    padding: 1rem 8rem;
}

.questions-wrapper {
    width: 50%;
}

.survey-modal-content {}

.view-user-answers-container {
    .questions-text {
        margin-bottom: 1px;
        font-size: 16px;
        font-weight: bold;
    }
    .ans-container {
        margin: 10px 0px 15px 15px;
        .ans-text {
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 2px;
        }
    }
}

.survey-answer-main-wrapper {
    height: 200px;
}

.survey-answer-pagination {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    justify-content: center;
    button {
        width: 40px;
        height: 40px;
        background-color: #000;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 5px;
        border: none;
    }
    span {
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 5px;
    }
}

.answer-go-back-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    div {
        width: 200px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #000;
        color: #fff;
        cursor: pointer;
        margin-right: 90px;
    }
}