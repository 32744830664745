.bulk-main-container{
    display: flex;
    flex-direction: column;
    width: 80%;
    align-items: center;
    padding: 10px 100px;
    .form-control {
        width: 100%;
        display: flex;
        flex-direction: column;
        input {
            width: 100%;
        }
    }
    .add-action {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        margin-top: 2rem;
        button {
            width: 170px;
            &:first-of-type {
                margin-right: 2%;
            }
        }
        .modal-button-loader {
            position: absolute;
            right: 22%;
            color: #ffffff !important;
        }
    }
    .btn-color{
        color: #ffffff !important;
    }
    .error-list{
      
        width: 100%;
        li{
            color: red;
        }
    }
}