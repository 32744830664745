.chip {
	padding: 1px 4px 1px 4px;
	border: 1px solid #c2c2c2;
	margin: 3px;
}

.text {
	color: #c2c2c2;
	font-size: 12px;
	margin-bottom: 0px;
}
