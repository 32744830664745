@import "../../assets/css/customStyles.scss";
.login-wrapper {
	@extend %flex-row-hv-center;
	height: 100%;
	background-color: #f5f5f5;
	.login-box {
		width: 24rem;
		border-radius: 5px;

		.logo-box {
			display: flex;
			justify-content: center;
			img.logo-icon {
				// margin-right: 13px;
				width: 90px;
			}
			img.logo-text {
				width: 82px;
			}
		}

		.sub-text {
			font-size: 11px;
			letter-spacing: 0px;
			color: #58514b;
			text-align: center;
			position: relative;
			top: -6px;
			display: inline;
			left: 46%;
			font-weight: 500;
		}

		.login-form {
			height: 100%;
			margin-top: 1.2rem;
			padding: 1rem 2.5rem 2rem;
			background-color: #fff;
			box-shadow: $bs-light;
			border-radius: 7px;
			&-header {
				font-size: 18px;
				font-weight: 700;
				text-align: center;
				padding-bottom: 2rem;
			}
			.form-control {
				@extend %flex-column;
				font-size: 12px;
				width: 100%;
				margin-bottom: 1rem;
				.font-password {
					font-size: 26px;
					letter-spacing: 4px;
				}
				button {
					position: relative;
					width: 100%;
					height: 50px;
					font-weight: 500;
					border-color: #58514b;
					border-radius: 2px;
					.login-progress {
						position: absolute;
						left: 46%;
						color: #58514b;
					}
				}
			}
			.forget-link {
				margin-top: 0;
				cursor: pointer;
				display: inherit;
				float: right;
				font-size: 12px;
				font-weight: 500;
				margin-bottom: 1rem;
				a {
					color: #58514b;
				}
			}
		}

		.login-footer {
			text-align: center;
			padding-top: 1rem;
			font-size: 15px;
			font-weight: 500;
			color: #001f45;
			a {
				color: #58514b;
				margin-left: 6px;
			}
		}
	}
}
