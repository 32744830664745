.add-slider-dialog {
	.dialog-title {
		margin: 0 !important;
		background-color: #f5f5f5;
		border-bottom: 1px solid #eeeeee;
		h2 {
			display: flex;
			width: 100%;
			justify-content: center;
			align-items: center;
		}
	}

	.add-slider-content {
		display: flex;
		flex-direction: column;
		padding: 30px;
		font-size: 15px;

		.add-slider-body {
			overflow-y: scroll;
			&::-webkit-scrollbar {
				-webkit-appearance: none;
			}
			&::-webkit-scrollbar:vertical {
				width: 1px;
			}
			&::-webkit-scrollbar:horizontal {
				height: 0px;
			}
			&::-webkit-scrollbar-thumb {
				background-color: #c4c9ca;
				border-radius: 0px;
				border: 2px solid transparent !important;
			}
			&::-webkit-scrollbar-track {
				border-radius: 50%;
				background-color: transparent !important;
			}
		}

		// Slider Details
		.add-slider-body-top {
			display: flex;
			flex-direction: column;
			width: 100%;
			margin: 0.5rem 0;
			justify-content: center;
			.add-slider-image-box {
				display: flex;
				.form-control-image-box {
					margin-bottom: 1rem;
					.upload-box {
						display: flex;
						background-color: #58514b;
						flex-direction: column;
						width: 14rem;
						height: 5rem;
						justify-content: center;
						align-items: center;
						border: 1px solid #cdcdcd;
						border-radius: 3px;
						font-size: 11px;
						color: #fff;
						font-weight: 500;
						cursor: pointer;
						img.image-upload {
							margin-bottom: 4px;
							width: 23px;
							height: 23px;
						}
						img.media-thumb {
							width: 100%;
							max-height: 100%;
							object-fit: contain;
						}
						label {
							text-align: center;
							cursor: pointer;
						}
						&.mobile-upload-box {
							width: 11rem;
						}
					}
					.bg-white {
						background-color: #fff;
					}
				}
			}
			.add-slider-description {
				margin-top: 1rem;
				input {
					width: 100%;
				}
			}

			.form-control {
				display: flex;
				flex-direction: column;
				justify-content: center;
				input {
					height: 40px;
					width: 100%;
				}
			}
			input[type="date"] {
				background-image: url("../../../assets/images/common/date.svg");
				background-repeat: no-repeat;
				background-position: 91% 50%;
				background-size: 15px;
			}
		}
		.add-slider-action {
			display: flex;
			width: 100%;
			justify-content: center;
			margin-top: 2rem;
			button {
				width: 30%;
				height: 2rem;
				&:first-of-type {
					margin-right: 2%;
				}
			}
			.modal-button-loader {
				position: absolute;
				left: 53%;
				color: #ffffff !important;
			}
		}
		.mr-custom {
			margin-right: 10px;
		}
	}
}
