#auction-details-id {
  //   overflow-x: hidden;
  overflow-y: scroll;

  height: calc(100% - 6rem);
  //margin: 4rem 6rem;
  justify-content: center;
  // background: #ffffff;
  // box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.18);
  // border-radius: 8px;
  padding: 1rem 8rem;

  .MuiSnackbar-root-1 {
    z-index: 2800;
  }

  .form-control {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 1.5rem;
  }

  .form-date-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .form-control-date {
    width: 45%;
    display: flex;
    flex-direction: column;
    padding-bottom: 1.5rem;
  }

  .artwork-type {
    padding-top: 2rem;
  }

  .auction-details-action {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-end;
    margin-top: 2rem;
    button {
      width: 120px;
      &:last-of-type {
        margin-left: 2%;
        background: black;
        color: white;
      }
    }
  }

  .auction-details-content {
    width: 100%;
    font-size: 15px;
    display: flex;
    flex-direction: column;
    padding: 2% 14%;
    overflow-y: unset;
    .auction-details-body {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
    }
  }

  .auction-details-dialog {
    .dialog-title {
      border-bottom: 1px solid #eee;
      h2 {
        font-size: 28px !important;
        font-weight: 700;
        color: #58514b;
      }
    }
  }

  .product-iv-title {
    font-size: 14px;
    margin: 1rem 0 5px 3px;
    font-weight: 500;
    color: #707070;
  }

  .product-iv-contents {
    display: flex;
    flex-wrap: wrap;
    .display-hover-screen {
      position: absolute;
      display: none;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: rgba(255, 255, 255, 0.97);
      bottom: 0;
      border-radius: 3px;
      font-size: 13px;
      padding: 0 1rem;
      text-align: center;
      height: 100%;
      width: 100%;
    }
    .product-iv-item {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #cdcdcd;
      border-radius: 5px;
      height: 10rem;
      width: 10rem;
      margin: 5px;
      cursor: pointer;
      img,
      .video-container {
        max-width: 100%;
        max-height: 100%;
        border-radius: 4px;
        margin-top: -1px;
      }
      .close-icon {
        position: absolute;
        top: -7px;
        right: -7px;
      }
      .download-icon {
        font-size: 27px;
        padding: 15px;
        background-color: rgba(218, 218, 218, 0.56);
        border-radius: 50%;
      }
      .edit-icon {
        margin-left: 5px;
        font-size: 27px;
        padding: 15px;
        background-color: rgba(218, 218, 218, 0.56);
        border-radius: 50%;
      }
      &:hover {
        .display-hover-screen {
          display: flex;
          .download-icon {
            animation: ZoomInKey 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
          }
          @keyframes ZoomInKey {
            0% {
              transform: scale(0.5);
            }
            100% {
              transform: scale(1);
            }
          }
        }
      }
    }
  }
}

#nft-auction-collection {
  background: #ffffff;
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.18);
  border-radius: 8px;
  padding: 1rem 5rem;
  margin: 3rem 2rem;

  .nft-collection-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .nft-dropdwn-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
