.report-wrapper{
    display: flex;
    justify-content: center;
    padding-top: 30px;
    .download-button{
        color: #fff;
        border:none;
        background-color: #FE9600;
        border-radius: 3px;
        padding: 12px 30px;
        cursor: pointer;
        font-size: 20px;
        font-weight: 500;
    }
    
}