.scroll-horizontal {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    overflow-x: scroll;

    .cascade-wrapper {
        border: 1px solid #CDCDCD;
        background-color: #FFF;
        border-radius: 3px;
        padding: 3px 10px 3px 3px;
        height: 40px;
        margin-bottom: 1rem;
        margin-right: 1rem;
        &.disabled {
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-width: 12rem;
            padding-left: 10px;
            
            p {
                font-size: 13px;
                display: inline-block;
                margin: 0;
            }
        }
        img {
            width: 20px;
        }
        &:focus, &:active, &:focus-within {
            border: 2px solid #FE9600;
        }
    }

    .onlySelect{
        margin-right: 0 !important;
        width: 100%;
        .ant-cascader-picker.scroll-cascader {
            width: 100% !important;
        }
    }

    .add-button-box {
        position: relative;
        width: 4rem;
        height: 4rem;
        top: -8px;
        img {
            max-width: 100%;
            max-height: 100%;
            cursor: pointer;
        }
    }
}

// Input Box
.ant-cascader-picker.scroll-cascader {
    height: 100%;
    
    .ant-cascader-input.ant-input {
        position: static;
        width: 100%;
        background-color: transparent !important;
        cursor: pointer;
        height: 100%;
        border: none;
    }
}
.scroll-cascader .ant-cascader-picker-label:hover + .ant-cascader-input,
.scroll-cascader.ant-cascader-picker:focus .ant-cascader-input,
.ant-input:focus {
    border-color: transparent !important;
    box-shadow: none !important;
    border-right-width: 1px !important;
}

// Dropdown
.ant-cascader-menus.scroll-cascader-menu {
    z-index: 1400 !important;
    margin-top: 3.5rem !important;
    width: 78% !important;
    overflow-x: scroll !important;
    left: 7% !important;
    border: 1px solid #E0E0E0;

    .ant-cascader-menu {
        min-width: 8rem !important;
    }
    .ant-cascader-menu-item:hover {
        background: #F8EFE3;
    }
    
    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }
    &::-webkit-scrollbar:vertical {
        width: 0px;
    }
    &::-webkit-scrollbar:horizontal {
        height: 5px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #F8EFE3;
        border-radius: 0px;
        border: 2px solid transparent !important;
    }
    &::-webkit-scrollbar-track {
        border-radius: 50%;
        background-color: transparent !important;
    }
}
.ant-cascader-menus.scroll-cascader-menu.onlySelect {
    width: auto !important;
}
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
    background-color: #F5F5F5 !important;
    color: #fe9600;
}