.container {
    margin: 2%;
    height: 90%;
    width: 100%;
    overflow: scroll;
    padding-bottom: 5%;
}
.paginationWrapper {
    margin: 2%;
}
.ant-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
}
.ant-pagination-prev {
    min-width: 22px;
    height: 22px;
    line-height: 22px;
}
.ant-pagination-item {
    min-width: 22px;
    height: 22px;
    line-height: 22px;
    border-color: #eeeeee;
    border-radius: 0;
}
.ant-pagination-next {
    min-width: 22px;
    height: 22px;
    line-height: 22px;
}
.ant-pagination-jump-next {
    min-width: 22px;
    height: 22px;
    line-height: 22px;
}
.ant-pagination-jump-prev {
    min-width: 22px;
    height: 22px;
    line-height: 22px;
}
.ant-pagination-item a {
    color: #000000;
}
.ant-pagination-item:focus a,
.ant-pagination-item:hover a {
    color: #58514b;
}
.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a,
.ant-pagination-item.ant-pagination-item-active a {
    border-color: #58514b;
    color: #ffffff;
}
.ant-pagination-item-active {
    background-color: #58514b;
}
.ant-pagination-item:focus,
.ant-pagination-item:hover {
    border-color: #58514b;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis {
    color: #58514b;
}
.anticon {
    color: #58514b;
}
.ant-pagination-prev .ant-pagination-item-link {
    border-color: #eeeeee;
    border-radius: 0;
}
.ant-pagination-next .ant-pagination-item-link {
    border-color: #eeeeee;
    border-radius: 0;
}
.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
    border-color: #58514b;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: #58514b;
}

.add-button-box {
    display: flex;
    position: absolute;
    align-items: center;
    height: 45px;
    right: 2rem;
    bottom: 1rem;
    .add-btn {
        background-color: #ffffff;
        border: 1px solid #58514b;
        border-radius: 3px;
        height: 35px;
        border: 1px solid #58514b;
        display: flex;
        align-items: center;
        margin-right: 20px;
        padding-left: 10px;
        padding-right: 10px;
        box-shadow: 0.25px 0.25px 12px #00000029;
        cursor: pointer;
        color: #58514b;
        font-size: 12px;
        font-weight: 500;
        p {
            font-size: 20px;
            color: #58514b;
            margin: 0;
            padding-right: 5px;
            font-weight: 500;
        }
    }
}

