.editor-modal-dialog {
	.dialog-title {
		border-bottom: 1px solid #eee;
		h2 {
			font-size: 28px !important;
			font-weight: 700;
			color: #58514b;
		}
	}
	.editor-modal-content {
		display: flex;
		flex-direction: column;
		width: 100%;
		font-size: 15px;
		padding: 2% 14%;
		overflow-y: unset;
		.editor-main-wrapper {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 100%;

			.upload-box {
				display: flex;
				flex-direction: column;
				width: 9rem;
				height: 9rem;
				justify-content: center;
				align-items: center;
				border: 1px solid #cdcdcd;
				border-radius: 3px;
				font-size: 13px;
				color: #58514b;
				font-weight: 500;
				cursor: pointer;
				img.image-upload {
					margin-bottom: 0px;
					width: 50px;
				}
				img.media-thumb {
					max-width: 100%;
					max-height: 100%;
				}
			}
			.bg-white {
				background-color: #fff;
			}
			.form-control {
				display: flex;
				flex-direction: column;
				width: 100%;
				input {
					width: 100%;
				}
			}
			.rdw-editor-wrapper {
				width: 100%;
			}
			.ButtonsWrapper {
				width: 100%;
				text-align: right;
				margin-top: 2rem;
			}

			.CancelButton {
				cursor: pointer;
				width: 170px;
				margin: 0 10px 0 0;
				height: 50px;
				border-radius: 3.5px;
				color: #000000;
				border: 0.25px solid #58514b;
			}

			.PublishButton {
				position: relative;
				cursor: pointer;
				width: 170px;
				margin: auto;
				height: 50px;
				border-radius: 3.5px;
				color: #ffffff;
				background-color: #58514b;
				border: 0.25px solid #58514b;
				.modal-button-loader {
					position: absolute;
					left: 10%;
					color: #ffffff !important;
				}
			}
		}
	}
}
