.dashboard-wrapper {
    display: flex;
    flex-direction: column;
    padding-left: 40px;
    padding-right: 40px;
    height: calc(100% - 4rem);
    overflow-y: scroll;
    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }
    &::-webkit-scrollbar:vertical {
        width: 12px;
    }
    &::-webkit-scrollbar:horizontal {
        height: 0px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #c4c9ca;
        border-radius: 0px;
        border: 2px solid transparent !important;
    }
    &::-webkit-scrollbar-track {
        border-radius: 50%;
        background-color: transparent !important;
    }
    &-totallist {
        display: flex;
        padding: 0px;
        &-item {
            display: flex;
            width: 28%;
            margin: 10px;
            background-color: #d2502d;
            padding: 2px 16px;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            &:first-of-type {
                margin-left: 0;
            }
            &:last-of-type {
                margin-right: 0;
            }
            .small-box {
                padding: 10px 0;
            }
            p {
                margin-bottom: 0;
                text-align: left;
                font-weight: 400;
                color: #ffffff;
            }
            .title {
                font-size: 24px;
            }
            .title-text {
                font-size: 14px;
            }
            img {
                height: 48px;
                width: 48px;
            }
            &.clickable {
                cursor: pointer;
                &:hover {
                    box-shadow: 3px 3px 6px -1px rgba(121, 85, 72, 0.7803921568627451);
                }
            }
            &.hidden {
                opacity: 0;
            }
        }
        &.ptop {
            padding-top: 3rem;
        }
        &.pbot {
            padding-bottom: 3rem;
        }
    }
    &-graph {
        display: flex;
        flex-direction: column;
        box-shadow: 0.25px 0.25px 2px #eee;
        //border: 1px solid #EEE;
        border-radius: 3px;
        &-title {
            display: flex;
            justify-content: space-between;
            padding: 15px 25px 10px 25px;
            .title {
                font-size: 16px;
                color: #000000;
                font-weight: 700;
            }
            .filter-year {
                display: flex;
                p {
                    font-size: 14px;
                    color: #000000;
                    padding-right: 7px;
                    padding-top: 5px;
                }
                .ant-select {
                    width: 100px;
                }
            }
        }
        .product-summary {
            display: flex;
            justify-content: center;
            &-info {
                display: flex;
                flex-direction: column;
                padding-right: 40px;
                &-title {
                    font-size: 12px;
                    color: #d2502d;
                    margin-bottom: 0;
                }
                &-value {
                    font-size: 16px;
                    color: #3e3e3e;
                    font-weight: 700;
                }
            }
        }
    }
}