.collectons-wrapper {
    overflow-x: hidden;
    overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
    height: calc(100% - 4rem);
    padding: 2rem 3rem 2rem 3.5rem;
    // position: relative
    // &::-webkit-scrollbar {
    // 	-webkit-appearance: none;
    // }
    // &::-webkit-scrollbar:vertical {
    // 	width: 0px;
    // }
    .collection-card {
        display: flex;
        flex-direction: column;
        margin-right: 2.5rem;
        margin-bottom: 3rem;
        height: 345px;
        .title-wrapper {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
        }
        .star-image {
            margin-left: 18px;
            height: 15px;
            width: 15px;
        }
        img {
            height: 300px;
            width: 350px;
            cursor: pointer;
            object-fit: fill;
        }
        .card-title {
            display: flex;
            padding-top: 15px;
            justify-content: space-between;
            p {
                margin: 0;
                color: #58514b;
                font-size: 16px;
                font-weight: 500;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                // width: 12rem;
            }
            .action-pill {
                display: flex;
                background-color: #ffffff;
                justify-content: center;
                align-items: center;
                width: 75px;
                height: 30px;
                border-radius: 15px;
                box-shadow: 0px 2px 4px 1px #d9d9d9;
                img {
                    height: 20px;
                    width: 20px;
                }
                span {
                    font-size: 18px;
                    margin-left: 5px;
                    margin-right: 5px;
                }
            }
        }
    }
    .add-button-box {
        display: flex;
        position: absolute;
        align-items: center;
        height: 45px;
        right: 2rem;
        bottom: 1rem;
        .add-btn {
            background-color: #ffffff;
            border: 1px solid #58514b;
            border-radius: 3px;
            height: 35px;
            border: 1px solid #58514b;
            display: flex;
            align-items: center;
            margin-right: 20px;
            padding-left: 10px;
            padding-right: 10px;
            box-shadow: 0.25px 0.25px 12px #00000029;
            cursor: pointer;
            color: #58514b;
            font-size: 12px;
            font-weight: 500;
            p {
                font-size: 20px;
                color: #58514b;
                margin: 0;
                padding-right: 5px;
                font-weight: 500;
            }
        }
    }
    .empty-collectons {
        display: flex;
        justify-content: center;
        height: calc(100% - 2rem);
        width: 100%;
        align-items: center;
        font-size: 15px;
        color: #000000;
    }
    .circular-loader {
        top: 50%;
        position: absolute;
        left: 56%;
    }
}

.collection-wrapper-disabled {
    pointer-events: none;
    .collection-card {
        opacity: 0.5;
        img {
            opacity: 0.4;
        }
    }
}