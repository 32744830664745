.ant-tabs-nav .ant-tabs-tab {
  text-align: center;
  font-weight: normal !important;
  font-size: 16px !important;
  letter-spacing: 0.4px;
  color: #7a7a7a !important;
  opacity: 1;
}

.ant-tabs-nav .ant-tabs-tab-active {
  color: #ffffff;
  color: #fff !important;
  background-color: black;
}

.ant-tabs-ink-bar {
  background-color: black;
}

.ant-tabs {
  margin-top: 10px;
}

.profile-pic-wrapper {
  height: 150px;
  display: flex;
  width: 100%;
  justify-content: center;
}

.camera-icon-wrapper {
  background: black;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 59%;
  right: -4px;
  justify-content: center;
  z-index: 2;
  border-radius: 20px;
  display: flex;
}

.about-form-section {
  width: 70%;
  align-self: center;
  margin-top: 2rem;
}

.about-wrapper-section {
  width: 70%;
  align-self: center;
  margin-top: 50px;
}

.about-wrapper-section .ant-btn-group {
  display: block !important;
}

.submit-button-wrapper {
  align-self: center;
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
}

.table-top-wrapper {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  align-items: baseline;
  margin: 10px 0px;
}

.table-top-wrapper-with-content {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: baseline;
  margin: 10px 0px;
}

.table-top-wrapper-with-content p {
  width: 70%;
  text-align: left;
  font: normal normal normal 14px/24px Lato;
  font-weight: normal !important;
  font-size: 14px !important;
  letter-spacing: 0.35px;
  color: #656565;
  opacity: 1;
}

.table-top-wrapper p {
  width: 70%;
  text-align: left;
  font: normal normal normal 14px/24px Lato;
  font-weight: normal !important;
  font-size: 14px !important;
  letter-spacing: 0.35px;
  color: #656565;
  opacity: 1;
}

.plus-button-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  flex-direction: column;
  align-items: flex-end;
}

.profile-form-wrapper {
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.highlight-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  width: 100%;
}

.highlight-wrapper p {
  width: 100%;
  border: 1px solid #e5e5e5;
  padding: 10px;
  height: 45px;
}

.overview-editor {
  width: 100%;
}

.rdw-editor-wrapper {
  width: 100%;
}

.payment-div-wrapper {
  width: 216px;
  height: 108px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e5e5e5;
  margin: 10px;
  justify-content: center;
  align-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  color: black;
  flex-direction: column;
}

.selected-card {
  background: #000000 0% 0% no-repeat padding-box;
  border: solid 1px black;
  color: white !important;
}

.selected-btn {
  background-color: black;
  color: white;
}

.payment-div-wrapper img {
  width: 80px;
  height: 40px;
  opacity: 1;
}

.payment-div-wrapper p {
  font-weight: normal !important;
  font-size: 14px !important;
  margin-top: 5px;
  font: normal normal normal 14px/23px Lato;
  text-transform: uppercase;
}

.sub-text-additional-info {
  color: black;
  text-align: left;
  font-weight: normal !important;
  font-size: 14px !important;
  font: normal normal normal 14px/23px Lato;
  letter-spacing: 0.38px;
  opacity: 1;
}

.profile-tab-description {
  display: flex;
  justify-content: space-around;
  align-content: center;
  align-items: baseline;
  margin-top: 60px;
  margin-bottom: 10px;
}

.profile-tab-description p {
  font-size: 14px !important;
  text-align: center;
  color: black;
  width: 80%;
}

.highlight-items {
  display: flex;
}

.art-image-wrapper {
  display: flex;
}

.uploaded-art-image {
  width: 300px;
  height: 200px;
  margin: 0 10px;
  object-fit: contain;
}

.required-start-span {
  color: red;
  font-size: 16px;
  font-weight: 800 !important;
  margin-left: 4px;
}

.artist-profile-main-wrapper {
  display: flex;
}

@media screen and (max-width: 786px) {
  .about-form-section {
    width: 100%;
  }
  .about-wrapper-section {
    width: 90%;
  }
  .artist-profile-form-row {
    flex-direction: row;
  }
}
