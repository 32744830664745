.user-profile-main {
  display: flex;
  height: calc(100% - 4rem);
  overflow-y: scroll;
  // height: 100%;
  @media screen and (max-width: 1395px) {
    overflow-x: scroll;
    overflow-y: scroll;
  }
  &-profile {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 320px !important;
    border-right: 1px solid #eee;
    padding: 20px;
    .load-message {
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;
      color: #000000;
      font-size: 14px;
      font-weight: 500;
    }
    &-view {
      display: flex;
      .profile-img {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 0.25px solid #5a5a5a;
        img {
          object-fit: fill;
          width: 12rem;
          height: 12rem;
        }
      }
    }

    .name {
      font-size: 20px;
      font-weight: bold;
      color: #000000;
      padding-top: 3px;
      padding-bottom: 2px;
      margin-bottom: 0px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .skillContainer {
      display: flex;
      margin-top: 5px;
      width: 100%;
      padding-bottom: 10px;
      border-bottom: 1px solid #c2c2c2;
      flex-wrap: wrap;
    }
    .featured {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
    }
    .star {
      color: #d2502d;
    }
    .countContainer {
      border-top: 1px solid #c2c2c2;
      border-bottom: 1px solid #c2c2c2;
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;

      &-count {
        // border-right: 1px solid #c2c2c2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 5px 40px 5px 5px;
        font-size: 12px;
      }
      &-count-noBorder {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 5px 40px 5px 5px;
        font-size: 12px;
      }
    }

    .contact {
      padding-top: 15px;
      padding-bottom: 15px;

      &-rows {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .sub-wrapper {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #f5f5f5;
        padding-top: 10px;
        ul {
          list-style-type: none;
          padding-left: 0;
          li {
            color: #565656;
            font-size: 14px;
            font-weight: 500;
            padding-bottom: 0px;
          }
          .greylist {
            color: #565656;
            font-size: 14px;
            font-weight: 500;
            padding-bottom: 0px;
          }
        }
        .detail-values {
          list-style-type: none;
          li {
            color: #000000;
            font-size: 14px;
            font-weight: 600;
            padding-bottom: 10px;
            img {
              height: 90px;
              width: 90px;
              object-fit: cover;
              margin-left: 2.5rem;
            }
            h2 {
              color: #000000;
              font-size: 14px;
              font-weight: 600;
              margin: 0;
              padding-top: 10px;
              padding-bottom: 10px;
              padding-left: 2.5rem;
            }
            .description {
              margin: 0;
              color: #000000;
              font-size: 13px;
              font-weight: 400;
              padding-left: 2.5rem;
            }
            .artist-name {
              color: #1a1718;
              font-size: 14px;
              font-weight: 500;
            }
            .addressform {
              display: flex;
              flex-direction: column;
              .nameText {
                padding-bottom: 10px;
                color: #000000;
                font-size: 16px;
                font-weight: 900;
                margin: 0;
              }
              .addressData {
                color: #000000;
                font-size: 13px;
                font-weight: 400;
                margin: 0;
              }
            }
          }
        }
      }
      .first-imagebutton {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
        height: 35px;
        width: 150px;
        cursor: pointer;
        margin-bottom: 10px;
        .first-iconimage {
          margin-right: 10px;
          height: 18px;
          width: 18px;
        }
        .title {
          font-weight: 500;
          font-size: 14px;
          color: #000000;
          margin: 0;
        }
      }
    }
    .view-more {
      display: flex;
      align-items: center;
      color: #d2502d;
      justify-content: center;
      cursor: pointer;
    }
  }

  .tabs-container {
    width: 100%;
    // overflow-y: scroll;
    height: 100%;
  }

  .tabs {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    position: sticky;

    &-switch {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      cursor: pointer;

      &-dark {
        background: #58514b;
        color: #fff;
      }

      &-white {
        background: #e1e1e1;
        color: #000;
      }
    }
  }
}

.history-container {
  height: 100%;
  // height: 85vh;
  // overflow-x: scroll;
  // overflow-y: scroll;
}

.marginPhone {
  margin-left: 32px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.marginEmail {
  margin-left: 35px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.marginDate {
  margin-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// ::-webkit-scrollbar {
//     display: none;
// }
