.shippingcharges-wrapper {
	overflow-x: hidden;
	overflow-y: scroll;
	display: flex;
	flex-direction: column;
	height: calc(100% - 4rem);
	// &::-webkit-scrollbar {
	// 	-webkit-appearance: none;
	// }
	// &::-webkit-scrollbar:vertical {
	// 	width: 0px;
	// }
	.filter-wrapper {
		display: flex;
		align-items: center;
		margin-top: 10px;
		margin-bottom: 10px;
		margin-left: 2rem;
		.filter-img {
			width: 26px;
			margin-right: 5px;
		}
	}
	.sub-wrapper {
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: space-between;
		@media screen and (max-width: 1395px) {
			overflow-x: scroll;
			overflow-y: scroll;
		}
		// .ant-table-wrapper {
		// 	 overflow: scroll;
		// }
		.colWrapper {
			display: flex;
		}
		.labelImage {
			position: absolute;
			top: 0px;
			left: -0.5px;
		}
		.colImage {
			height: 60px;
			width: 60px;
			object-fit: fill;
		}
		.colDiv {
			display: flex;
		}
		.colText {
			color: #000000;
			margin: 0;
			font-size: 14px;
			font-weight: 600;
			width: 7rem;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
		.colGreyText {
			color: #727272;
			margin: 0;
			font-size: 12px;
			width: 7rem;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
		.rftext {
			margin: 0;
			font-size: 14px;
			font-weight: 500;
			color: #000000;
			width: 6rem;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
		.textBlack {
			margin: 0;
			font-size: 14px;
			font-weight: 500;
			color: #000000;
			width: 7rem;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
		.blackText {
			margin: 0;
			font-size: 14px;
			font-weight: 500;
			color: #000000;
		}
		.textCenterBlack {
			margin: 0;
			font-size: 14px;
			font-weight: 500;
			color: #000000;
			text-align: center;
		}
		.textOrange {
			margin: 0;
			font-size: 14px;
			font-weight: 500;
			color: #fe9600;
		}

		.textBlue {
			margin: 0;
			font-size: 14px;
			font-weight: 500;
			color: #0b969b;
		}
		.textDarkBlue {
			margin: 0;
			font-size: 14px;
			font-weight: 500;
			color: #0072d6;
		}
		.textGreen {
			margin: 0;
			font-size: 14px;
			font-weight: 500;
			color: #499b0b;
		}
		.textBrown {
			margin: 0;
			font-size: 14px;
			font-weight: 500;
			color: #d2502d;
		}
		.textRed {
			margin: 0;
			font-size: 14px;
			font-weight: 500;
			color: #9b0b1e;
		}
		.dropDown {
			display: flex;
			align-items: center;
			height: 35px;
			background-color: #f5f5f5;
			border-radius: 4px;
			width: 11rem;
			justify-content: space-between;
			padding-left: 10px;
			padding-right: 10px;
			.anticon.anticon-down {
				font-weight: 900;
			}
		}
		.artistDiv {
			display: flex;
			align-items: center;
			img {
				height: 11px;
				width: 11px;
				margin-right: 8px;
				margin-bottom: 2px;
			}
			p {
				margin: 0;
				font-size: 14px;
				font-weight: 500;
				color: #000000;
				width: 6rem;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}
		.empty-table {
			display: flex;
			justify-content: center;
			height: calc(100% - 2rem);
			width: 100%;
			align-items: center;
			font-size: 15px;
			color: #000000;
		}
		.add-button-box {
			display: flex;
			position: absolute;
			align-items: center;
			height: 45px;
			right: 2rem;
			bottom: 1rem;
			.add-btn {
				background-color: #ffffff;
				border: 1px solid #58514b;
				border-radius: 3px;
				height: 35px;
				border: 1px solid #58514b;
				display: flex;
				align-items: center;
				margin-right: 20px;
				padding-left: 10px;
				padding-right: 10px;
				box-shadow: 0.25px 0.25px 12px #00000029;
				cursor: pointer;
				color: #58514b;
				font-size: 12px;
				font-weight: 500;
				p {
					font-size: 20px;
					color: #58514b;
					margin: 0;
					padding-right: 5px;
					font-weight: 500;
				}
			}
		}
		.ant-select {
			margin-right: 10px;
		}
		.ant-select-selection:focus,
		.ant-select-selection:active,
		.ant-select-selection:hover {
			border-color: #000000;
			-webkit-box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2);
			box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2);
		}
		.ant-table {
			color: #000000;
			font-weight: 500;
			margin-bottom: 40px;
		}
		.ant-table-thead > tr > th {
			padding: 10px 10px 10px 2rem;
			background-color: #f5f5f5;
			color: #000000;
			&:last-child {
				padding: 10px 2rem 10px 10px;
			}
		}
		.ant-table-tbody > tr {
			// cursor: pointer;
		}
		.ant-table-tbody > tr > td {
			padding: 10px 10px 10px 2rem;
			&:last-child {
				padding: 10px 10px 10px 10px;
			}
		}

		.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
			background: #e3d2b0;
			box-shadow: 0px 5px 0px 0px #e3d2b0;
		}

		.ant-pagination {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 2rem;
			margin-bottom: 20px;
		}
		.ant-pagination-prev {
			min-width: 22px;
			height: 22px;
			line-height: 22px;
		}
		.ant-pagination-item {
			min-width: 22px;
			height: 22px;
			line-height: 22px;
			border-color: #eeeeee;
			border-radius: 0;
		}
		.ant-pagination-next {
			min-width: 22px;
			height: 22px;
			line-height: 22px;
		}
		.ant-pagination-jump-next {
			min-width: 22px;
			height: 22px;
			line-height: 22px;
		}
		.ant-pagination-jump-prev {
			min-width: 22px;
			height: 22px;
			line-height: 22px;
		}
		.ant-pagination-item a {
			color: #000000;
		}
		.ant-pagination-item:focus a,
		.ant-pagination-item:hover a {
			color: #58514b;
		}
		.ant-pagination-item-active:focus a,
		.ant-pagination-item-active:hover a,
		.ant-pagination-item.ant-pagination-item-active a {
			border-color: #58514b;
			color: #ffffff;
		}
		.ant-pagination-item-active {
			background-color: #58514b;
		}
		.ant-pagination-item:focus,
		.ant-pagination-item:hover {
			border-color: #58514b;
		}
		.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis {
			color: #58514b;
		}
		.anticon {
			color: #58514b;
		}
		.ant-pagination-prev .ant-pagination-item-link {
			border-color: #eeeeee;
			border-radius: 0;
		}
		.ant-pagination-next .ant-pagination-item-link {
			border-color: #eeeeee;
			border-radius: 0;
		}
		.ant-pagination-prev:focus .ant-pagination-item-link,
		.ant-pagination-next:focus .ant-pagination-item-link,
		.ant-pagination-prev:hover .ant-pagination-item-link,
		.ant-pagination-next:hover .ant-pagination-item-link {
			border-color: #58514b;
		}
		.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
		.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
			color: #58514b;
		}
		.circular-loader {
			top: 50%;
			position: absolute;
			left: 56%;
		}
	}
}

.shipping-charges-action-popover .ant-popover-inner {
	background-color: #000000;
}
.shipping-charges-action-popover.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
	border-top-color: #000000;
	border-left-color: #000000;
}
.shipping-charges-action-popover.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow {
	border-top-color: #000000;
	border-left-color: #000000;
	border-bottom-color: #000000;
	border-right-color: #000000;
}
.action-image {
	height: 25px;
	width: 25px;
	object-fit: contain;
	&:hover {
		cursor: pointer;
	}
}
.action-span {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	color: #8b8b8b;
	justify-content: flex-start;
	cursor: pointer;
	.anticon {
		margin-right: 8px;
	}
	img {
		height: 20px;
		width: 20px;
		margin-right: 5px;
	}
	.pop-item {
		color: #ffffff;
		margin: 0;
		&:hover {
			color: #ad9972;
		}
	}
}
