.add-rule-tax-dialog {
	.dialog-title {
		border-bottom: 1px solid #eee;
		h2 {
			font-size: 28px !important;
			font-weight: 700;
			color: #58514b;
		}
	}
	.add-rule-tax-content {
		width: 100%;
		font-size: 15px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 2% 14%;
		.table-wrapper {
			display: flex;
			width: 100%;
			flex-direction: column;
			.head-wrapper {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding-bottom: 1rem;
			}
			.main-content-wrapper {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
			.content-wrapper {
				display: flex;
				// flex-direction: column;
				width: 100%;
			}
			.head-item {
				width: 100%;
				color: #000000;
				font-size: 16px;
				font-weight: 600;
			}
			.content-item {
				width: 100%;
				color: #000000;
				font-size: 14px;
				font-weight: 400;
				padding-bottom: 10px;
			}
			.action-wrapper {
				display: flex;
				width: 50%;
				align-items: center;
				:first-child {
					margin-right: 1rem;
				}
			}
		}
		.dialog-sub-title {
			width: 100%;
			padding-bottom: 20px;
			span {
				font-size: 16px;
				font-weight: 600;
			}
		}

		.filter-close-btn {
			position: relative;
			float: right;
			top: -40px;
			right: 6px;
			cursor: pointer;
		}
		.add-filter-button {
			float: right;
			height: 33px;
			width: 5rem;
			padding: 3px 10px;
			border-radius: 3px;
			background-color: #fff;
			border: 1px solid #58514b;
			color: #58514b;
			font-size: 13px;
			font-weight: 500;
			text-transform: uppercase;
			transition: background-color 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
			&:hover,
			&:active,
			&:focus {
				background-color: #58514b;
				border: 1px solid #58514b;
				color: #fff;
			}
		}
		.add-rule-tax-body {
			display: flex;
			// flex-direction: column;
			width: 100%;
			align-items: center;
			margin-bottom: 10px;
			border: 1px solid #cdcdcd;
			border-radius: 5px;
			padding: 10px;
			// margin-bottom: 1rem;
			.upload-box {
				display: flex;
				background-color: #58514b;
				flex-direction: column;
				width: 9rem;
				height: 9rem;
				justify-content: center;
				align-items: center;
				border: 1px solid #cdcdcd;
				border-radius: 3px;
				margin-bottom: 1rem;
				font-size: 13px;
				color: #fff;
				font-weight: 500;
				cursor: pointer;
				img.image-upload {
					margin-bottom: 10px;
				}
				img.media-thumb {
					max-width: 9rem;
					max-height: 9rem;
					object-fit: fill;
					min-height: 9rem;
					min-width: 9rem;
				}
			}
			.bg-white {
				background-color: #fff;
			}
			.form-control {
				width: 100%;
				display: flex;
				flex-direction: column;
				input {
					width: 100%;
				}
			}
			.form-control-new {
				width: 100%;
				display: flex;
				flex-direction: column;
				margin-left: 1rem;
				input {
					width: 100%;
				}
			}
		}
		.add-rule-tax-featured-box {
			display: flex;
			width: 100%;
			margin-bottom: 1rem;
			justify-content: space-between;
			font-size: 14px;
			font-weight: 500;
			color: rgba(0, 0, 0, 0.85);
			padding: 0 5px;
			.ant-checkbox-inner {
				border-color: #707070;
			}
			.ant-checkbox-checked .ant-checkbox-inner {
				background-color: #fe9600;
			}
		}
		.add-rule-tax-action {
			display: flex;
			width: 100%;
			justify-content: flex-end;
			margin-top: 2rem;
			button {
				width: 170px;
				&:first-of-type {
					margin-right: 2%;
				}
			}
			.modal-button-loader {
				position: absolute;
				right: 22%;
				color: #ffffff !important;
			}
		}
	}
}
