// Refer Notes at the end of file
// Import this file to use these features
// @import '/src/assets/css/customStyles.scss';

// ------------------------- Sass Variables ----------------------- //
// Box shadow
$bs-light: 3px 4px 10px 2px #EEE;

// ------------------------- Placeholders (@extend %classname;) ----------------------- //
%flex-row {
    display: flex;
    flex-direction: row;
}
%flex-column {
    display: flex;
    flex-direction: column;
}
%flex-row-hv-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
%flex-column-hv-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

// ------------------------- Mixins (@include classname(param-if-any);) ----------------------- //


// ------------------------------------------- NOTES ------------------------------------------- //

// TODO : REMOVE ALL NOTES BEFORE FINAL DEPLOYMENT

// Variables are one of the more straightforward features of Sass but are still on occasion used incorrectly.
//  Creating a site-wide naming convention is essential when working with Variables.
// Without one, they become harder to understand and re-use.
$example-orange: #ffa600;

.example-class {
    color: $example-orange;
}

// A mixin is a great way to include sections of code multiple times within a site.
// However, including a mixin is the same as copying and pasting the styles throughout the CSS file.
// It creates a mass of duplicate code and can bloat your CSS file.

// CODE
@mixin example-rounded-corner($arc) {
    -moz-border-radius: $arc;
    -webkit-border-radius: $arc;
    border-radius: $arc;  
}

// USAGE
.example-tab-button {
    @include example-rounded-corner(5px); 
}

// Unlike mixins, placeholders can be used multiple times without adding any duplicate code.
// This makes them a much friendlier option for outputting DRY CSS:
%example-bg-image {
    width: 100%;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}
.example-image {
    @extend %example-bg-image;
}