.add-coupon-dialog {
  .dialog-title {
    border-bottom: 1px solid #eee;
    h2 {
      font-size: 28px !important;
      font-weight: 700;
      color: #58514b;
    }
  }
  .add-coupon-content {
    width: 100%;
    font-size: 15px;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;
    padding: 2% 14%;
    overflow-y: unset;
    .add-coupon-body {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
    }

    .form-control {
      width: 100%;
      display: flex;
      flex-direction: column;
      input {
        width: 100%;
      }
    }
  }

  .add-coupon-action {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 2rem;
    button {
      width: 170px;
      &:first-of-type {
        margin-right: 2%;
      }
    }
  }

  .btn-orange {
    height: 45px;
    padding: 3px 10px;
    border-radius: 3px;
    background-color: #58514b;
    border: 1px solid #bababa;
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    transition: background-color 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    &:hover,
    &:active,
    &:focus {
      opacity: 0.7;
    }
    &:disabled {
      background-color: #d0d5dc;
      border: 1px solid #d0d5dc;
      color: #fff;
      cursor: not-allowed;
      pointer-events: none;
      &:hover {
        transition: none;
      }
    }
  }

  .btn-cancel {
    height: 45px;
    padding: 3px 10px;
    border-radius: 3px;
    background-color: #e1e1e1;
    border: 1px solid #bababa;
    text-transform: uppercase;
    color: #323643;
    font-size: 13px;
    font-weight: 500;
    transition: background-color 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    &:hover,
    &:active,
    &:focus {
      opacity: 0.7;
    }
    &:disabled {
      background-color: #d0d5dc;
      border: 1px solid #d0d5dc;
      color: #fff;
      cursor: not-allowed;
      pointer-events: none;
      &:hover {
        transition: none;
      }
    }
  }
}
