.recharts-layer {
    .recharts-cartesian-axis-tick {
      &-value {
        font-size: 10px;
        color: #282D32;
      }
    }
  }
  .recharts-default-tooltip{
    font-size: 12px;
    color: #000;
    border: none;
  }