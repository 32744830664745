.add-carrer-level-dialog {
  .add-carrer-level-content {
    width: 25rem;
    font-size: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .add-carrer-level-body {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
      // margin-bottom: 1rem;
      .upload-box {
        display: flex;
        background-color: #58514b;
        flex-direction: column;
        width: 9rem;
        height: 9rem;
        justify-content: center;
        align-items: center;
        border: 1px solid #cdcdcd;
        border-radius: 3px;
        margin-bottom: 1rem;
        font-size: 13px;
        color: #fff;
        font-weight: 500;
        cursor: pointer;
        img.image-upload {
          margin-bottom: 10px;
        }
        img.media-thumb {
          max-width: 9rem;
          max-height: 9rem;
          object-fit: fill;
          min-height: 9rem;
          min-width: 9rem;
        }
      }
      .bg-white {
        background-color: #fff;
      }
      .form-control {
        width: 100%;
        display: flex;
        flex-direction: column;
        input {
          width: 100%;
        }

        select {
          height: 35px;
          padding: 5px;
          border-radius: 0 !important;
        }
      }
    }
    .ant-calendar-picker {
      margin: 0rem 0rem 1rem;
    }
    .ant-input {
      border: 1px solid #cdcdcd;
      border-radius: 3px;
      height: 45px;
      padding: 0px 10px;
      font-size: 14px;
      font-weight: 500;
      color: #323643;
    }
    .ant-select-selection:focus,
    .ant-select-selection:active,
    .ant-select-selection:hover {
      border-color: #000000;
      -webkit-box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2);
      box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2);
    }
    .add-carrer-level-featured-box {
      display: flex;
      width: 100%;
      margin: 1rem 0;
      // justify-content: space-between;
      font-size: 14px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      padding: 0 5px;
      span {
        padding-right: 10px;
      }
      .ant-checkbox-inner {
        border-color: #707070;
      }
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #707070;
      }
    }
    .add-carrer-level-action {
      display: flex;
      width: 100%;
      justify-content: center;
      button {
        width: 49%;
        &:first-of-type {
          margin-right: 2%;
        }
      }
      .modal-button-loader {
        position: absolute;
        left: 70%;
      }
    }
  }
}
.ant-calendar-picker-container {
  z-index: 2000 !important;
}
