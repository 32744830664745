.colWrapper {
	display: flex;
}

.main {
	height: 100%;
	padding-bottom: 10vh;
	margin-bottom: 20px;
}

.colImage {
	height: 70px;
	width: 70px;
	object-fit: cover;
}

.countText {
	display: flex;
	align-items: center;
}
.textBlue {
	margin: 0;
	font-size: 14px;
	font-weight: 500;
	color: #0b969b;
}
.textDarkBlue {
	margin: 0;
	font-size: 14px;
	font-weight: 500;
	color: #0072d6;
}
.textGreen {
	margin: 0;
	font-size: 14px;
	font-weight: 500;
	color: #499b0b;
}
.textBrown {
	margin: 0;
	font-size: 14px;
	font-weight: 500;
	color: #d2502d;
}
.textRed {
	margin: 0;
	font-size: 14px;
	font-weight: 500;
	color: #9b0b1e;
}
.overflow {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 400px;
}

.overflow-ls {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 200px;
}
