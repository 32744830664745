.two-factor-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: #f5f5f5;
	height: 100%;
	.app-logo {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding-bottom: 30px;
	}
	.two-factor-contents {
		display: flex;
		flex-direction: column;
		width: 393px;
		height: 340px;
		padding-bottom: 40px;
		background-color: #ffffff;
		padding: 30px 30px 30px 30px;
		@media screen and (max-width: 430px) {
			width: 80%;
		}
		.page-title {
			font-size: 16px;
			color: #434343;
			margin: 0;
			text-align: center;
			font-weight: 700;
			padding-bottom: 1rem;
		}
		.message {
			margin: 0;
			font-size: 14px;
			color: #434343;
			font-weight: 500;
			text-align: center;
			padding-bottom: 20px;
		}
		.form-control {
			width: 100%;
			display: flex;
			flex-direction: column;
			input {
				width: 100%;
				margin-bottom: 25px;
			}
			input[type="number"]::-webkit-inner-spin-button,
			input[type="number"]::-webkit-outer-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}
		}
		.end-btn {
			display: flex;
			flex-direction: column;
			button {
				height: 45px;
				width: 100%;
				position: relative;
			}
			p {
				font-size: 14px;
				color: #fe9600;
				text-align: center;
				padding-top: 10px;
				font-weight: 500;
				cursor: pointer;
			}
		}
	}
	.two-factor-progress {
		position: absolute;
		top: 20%;
		left: 47%;
		color: #fe9600;
	}
}
