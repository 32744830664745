#auctions {
  height: calc(100% - 6rem);
  .ant-pagination-item.ant-pagination-item-active a {
    color: black !important;
    margin-left: 5px;
  }
  .ant-table-pagination.ant-pagination {
    float: none;
  }
  .auctions-list {
    width: 100%;
    border-right: 1px solid #eee;
    box-shadow: 3px 3px 8px -2px #eee;
    z-index: 11;
    height: 100vh;
    @media screen and (max-width: 1600px) {
      overflow-x: scroll;
      overflow-y: scroll;
    }
  }
  .auctTable {
    height: calc(100% - 6rem);
  }
  .add-button-box {
    display: flex;
    position: absolute;
    align-items: center;
    height: 45px;
    right: 2rem;
    bottom: 1rem;
    .add-btn {
      background-color: #ffffff;
      border: 1px solid #58514b;
      border-radius: 3px;
      height: 35px;
      border: 1px solid #58514b;
      display: flex;
      align-items: center;
      margin-right: 20px;
      padding-left: 10px;
      padding-right: 10px;
      box-shadow: 0.25px 0.25px 12px #00000029;
      cursor: pointer;
      color: #58514b;
      font-size: 12px;
      font-weight: 500;
      p {
        font-size: 20px;
        color: #58514b;
        margin: 0;
        padding-right: 5px;
        font-weight: 500;
      }
    }
  }
  .add-btn-fixed {
    position: fixed !important;
    bottom: 20px;
    right: 100px;
    z-index: 999;
  }
}

.nft-calender-main-container {
  display: flex;
  align-items: center;
  .nft-calender-section-block-img {
    width: 20%;
  }
  .nft-calender-section-block {
    width: 35%;
    margin: 0 5px;
  }
}
