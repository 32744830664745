.delete-mainCategory-dialog {
    .delete-mainCategory-content {
        width: 25rem;
        font-size: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p {
            font-size: 14px;
            text-align: center;
            line-height: 1.5;
        }

        .checkboxConfirmation {
            display: flex;
            width: 100%;
            margin-bottom: 1rem;
            justify-content: center;
            font-size: 14px;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.85);
            padding: 0 5px;
            label {
                margin-right: 5px;
            }
            .ant-checkbox-inner {
                border-color: #707070;
            }
            .ant-checkbox-checked .ant-checkbox-inner {
                background-color: #FE9600;
            }
        }
        
        button {
            height: 35px;
            min-width: 10rem;
            margin-top: 1rem;
            text-transform: inherit;
            .modal-button-loader {
                position: absolute;
                left: 50%;
            }
        }
    }
}