.add-property-dialog {
    .dialog-title {
        border-bottom: 1px solid #EEE;
        h2{
            color: #D2502D;
        }
    }
    .add-property-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        font-size: 15px;
        .form-input {
            height: 35px;
        }
        .dialog-sub-title {
            text-align: left;
            margin-bottom: 1rem;
            font-size: 15px !important;
            font-weight: 500;
            color: #000;
        }
        .add-property-body {
            display: flex;
            align-items: center;
            .form-control {
                width: 100%;
                display: flex;
                flex-direction: column;
                padding-right: 1rem;
                input {
                    width: 100%;
                }
            }
        }
        .add-property-featured-box {
            display: flex;
            width: 100%;
            // margin-bottom: 0.5rem;
            // justify-content: space-between;
            font-size: 14px;
            font-weight: 500;
            // color: rgba(0, 0, 0, 0.85);
            padding: 0 5px;
            .ant-checkbox-inner {
            border-radius: 4px;
            }
            .ant-checkbox-wrapper {
                margin-right: 10px;
            }
            .ant-checkbox-inner {
                border-color: #707070;
            }
            .ant-checkbox-checked .ant-checkbox-inner {
                background-color: #D2502D;
            }
        }

        // Top wrapper
        .add-property-content-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .dialog-sub-title {
                min-width: 65%;
            }
            .add-property-body {
                width: 65%;
            }
            hr {
                color: #EEE !important;
                width: 75%;
                margin: 0;
                border-top: none;
                border-left: none;
                border-right: none;
            }
        }
        .add-suggestion-wrapper{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-top: 1rem;
            .suggestion-details{
                display: flex;
                background-color: #F6F6F6;
                border-radius: 3px;
                flex-direction: column;
                width: 65%;
                padding-bottom: 20px;

            }
            .dialog-sub-title {
                min-width: 65%;
                padding: 10px 20px;
                margin-bottom: 0;
            }
            .add-property-body {
            display: flex;
            align-items: center;
            position: relative;
            .form-control {
                width: 100%;
                display: flex;
                flex-direction: column;
                input {
                    width: 100%;
                }
            }
            .close-btn{
                position: absolute;
                right: 20px;
                bottom: 25px;
                padding: 0;
                border: 1px solid #F6F6F6;
                background-color: #F6F6F6;
                border-radius: 10px;
            }
        }
            .suggestion-list{
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                justify-content: space-around;
            }
            .add-property-featured-box {
            display: flex;
            width: 100%;
            // margin-bottom: 0.5rem;
            // justify-content: space-between;
            font-size: 14px;
            font-weight: 500;
            // color: rgba(0, 0, 0, 0.85);
            padding: 0 20px;
            .ant-checkbox-inner {
            border-radius: 4px;
            }
            .ant-checkbox-wrapper {
                margin-right: 10px;
            }
            .ant-checkbox-inner {
                border-color: #D2502D;
            }
            .ant-checkbox-checked .ant-checkbox-inner {
                background-color: #D2502D;
            }
        }

            .add-filter-button {
                float: right;
                height: 33px;
                width: 5rem;
                padding: 3px 10px;
                border-radius: 3px;
                background-color: #FFF;
                border: 1px solid #58514B;
                color: #58514B;
                font-size: 13px;
                font-weight: 500;
                text-transform: uppercase;
                transition: background-color 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                &:hover,
                &:active,
                &:focus {
                    background-color: #58514B;
                    border: 1px solid #58514B;
                    color: #FFF;
                }
            }
        }
        // Bottom wrapper
        .add-property-filter-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-top: 1rem;
            .filter-form-wrapper {
                display: flex;
                flex-direction: column;
                height: 100%;
                overflow-y: scroll;
                width: 100%;
                align-items: center;
            }
            .dialog-sub-title {
                min-width: 65%;
            }
            .add-property-body {
                border-radius: 5px;
                padding: 1rem 0rem 0rem 1rem;
                margin-bottom: 5px;
                min-width: 65%;
                justify-content: center;
                border: 1px solid #CDCDCD;
            }
            .filter-close-btn {
                position: relative;
                float: right;
                top: -40px;
                right: 6px;
                cursor: pointer;
            }
            .add-filter-button {
                float: right;
                height: 33px;
                width: 5rem;
                padding: 3px 10px;
                border-radius: 3px;
                background-color: #FFF;
                border: 1px solid #58514B;
                color: #58514B;
                font-size: 13px;
                font-weight: 500;
                text-transform: uppercase;
                transition: background-color 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                &:hover,
                &:active,
                &:focus {
                    background-color: #58514B;
                    border: 1px solid #58514B;
                    color: #FFF;
                }
            }
        }
    }
    .add-property-action {
        display: flex;
        justify-content: center;
        button {
            width: 25%;
            height: 35px;
            &:first-of-type {
                margin-right: 2%;
            }
        }
        .modal-button-loader {
            position: absolute;
            left: 70%;
        }
    }
}
