#auction-summary-id {
  overflow-y: scroll;
  //   display: flex;
  //   flex-direction: column;
  height: calc(100% - 2rem);
  // margin: 2rem 8rem;
  justify-content: center;
  // background: #ffffff;
  // box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.18);
  // border-radius: 8px;
  padding: 1rem 5rem;

  .summary-heading {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .summary-ant-btn {
    border: 2px solid #000000;
    border-radius: 4px;
    font-family: Lato;
    color: #000000;
    font-weight: 500;
  }

  .launch-ant-btn {
    background-color: black;
    color: white;
    font-weight: 500;
  }

  .hr-divider {
    background: #000000;
    margin-top: 1.5rem;
  }

  .auc-time-sec {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .auc-start-date {
    width: 50%;
    margin-top: 2rem;
    text-align: left;
    border-right: 2px solid #000000;
  }
  .auc-end-date {
    margin-top: 2rem;
  }

  .auc-date-time {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }

  .auc-date-day {
    font-size: 2.5rem;
    font-weight: 300;
  }

  /* summary details */
  .summary-details-divider {
    background: #000000;
    margin-top: 1.5rem;
  }

  

  .art-items {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 2rem;
    border-bottom: 1px solid #000000;
    padding-bottom: 1rem;
    &:last-of-type {
      border-bottom: none;
    }
  }

  .flx-grw-colum {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    flex-grow: 1;
  }

  .flx-grw-row {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
  }

  .art-select-order {
    margin: auto 0;
    font-size: 3rem;
    font-weight: 300;
  }

  .btn-wrap {
    display: flex;
    flex-wrap: wrap;
    //justify-content: center;
    gap: 0.5rem;
    width: 100%;
  }

  .row-img {
    height: 15rem;
    width: 15rem;
    text-align: center;
    background-color: #f9ebef;
    padding: 0;
  }

  .img-thumb {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .reserved-price {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 0.9rem;
  }

  .price-item {
    width: 45%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    input {
      background: #fff7f9;
      border-radius: 4px;
    }
  }

  .lot-date-details {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 0.9rem;
  }

  .toggle_rank_btn-grp {
    position: absolute;
    bottom: 0;
  }

  .empty-artwork {
    margin: 4rem;
  }

  .gutter-box {
    padding: 8px 0;
  }

  /* summary details end */

  /* stepper */
  .auc-stepper {
    margin: 3rem;
  }

  .launch-auction-btn {
    justify-content: center;
    gap: 1rem;
    margin: 2rem;
    button &:last-child {
      background-color: #000000;
      color: #ffffff;
    }
  }
  .ant-steps-item-finish .ant-steps-item-icon {
    border-color: #12bf23;
    background-color: #12bf23;
  }

  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: #ffffff;
    background-color: #12bf23;
  }

  .ant-pagination-item.ant-pagination-item-active a {
    
    color: #000000;
  }

  .ant-steps-item-process .ant-steps-item-icon {
    background: #12bf23;
    border-color: #12bf23;
  }

  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    border-color: #12bf23;
    background-color: #12bf23;
  }
  .ant-steps-item-title::after {
    height: 2px;
  }

  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: #12bf23;
  }

  .lot-search {
    display: flex;
    align-items: center;
  
    input {
      border: 1px solid #eee;
      border-radius: 4px;
      width: 250px;
      padding: 5px 20px 5px 10px;
      background-image: url(../../../assets/images/common/Search.svg);
      background-repeat: no-repeat;
      background-position: 98% 50%;
      background-size: 22px;
  
      &:active,
      &:focus {
        border: 2px solid #58514b;
      }
    }
  }

}


