.wrapper {
    display: flex;
    height: 100%;
    .main-body-wrapper {
        width: 100%;
        overflow-x: scroll;
        overflow-y: hidden;
        &::-webkit-scrollbar {
            -webkit-appearance: none;
        }
        &::-webkit-scrollbar:vertical {
            width: 0px;
        }
        &::-webkit-scrollbar:horizontal {
            height: 0px;
        }
    }
}