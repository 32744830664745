.tile {
	display: flex;
	align-items: center;
	justify-content: space-around;
	width: 95%;
	border-bottom: 1px solid #eeeeee;
	padding: 10px 0;
	.imgContainer {
		height: 150px;
		width: 150px;

		&-image {
			height: 100%;
			width: 100%;
			object-fit: contain;
			cursor: pointer;
		}
	}

	.content {
		padding: 2px;
		width: 50rem !important;
		overflow: hidden;
		text-overflow: ellipsis;
		.title {
			font-size: 18px;
			font-weight: bold;
			color: #000;
			cursor: pointer;
		}
		.content-text {
			font-size: 12px;
			color: #656565;
		}
	}

	.action {
		padding: 35px;
		display: flex;
		flex-direction: column;
		margin-right: 10px;
	}

	.row {
		display: flex;
		cursor: pointer;
		align-items: center;
		img {
			height: 15px;
			width: 15px;
			margin-bottom: 5px;
			margin-right: 5px;
		}
	}
}
