.add-brands-wrapper {
    height: calc(100% - 4rem);
    .add-brands-table {
        height: 100%;
        &-list {
            padding: 0;
            margin: 0;
            list-style: none;

            // Table Header / Body 
            &-header, &-body {
                display: flex;
                align-items: center;
                &-item {
                    font-size: 13px;
                }
            }

            // Header
            &-header {
                height: 3rem;
                background-color: #F5F5F5;
                padding: 0rem 1rem 0 2rem;
                &-item {
                    font-weight: 700;
                }
            }

            &.bodyTable li {
                padding: 0rem 1rem;
                border-left: 3px solid #FFF;
                &:hover {
                    background-color: #F8EFE3;
                    transition: all 0.3s, height 0s;
                    border-left: 3px solid #FE9600;
                    cursor: pointer;
                }
                &:last-child {
                    margin-bottom: 3rem;
                }
            }

            // Body
            &-body {
                min-height: 4.5rem;
                padding-left: 1rem;
                border-bottom: 1px solid #F5F5F5;
                &-item {
                    font-weight: 500;
                    .text-pending {
                        color: #009900;
                    }
                }
            }

            // Brand
            .cell-one {
                display: flex;
                align-items: center;
                width: 50%;
                .image-box {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 4rem;
                    height: 4rem;
                    margin-right: 7%;
                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }

            // Created By / Status
            .cell-four,
            .cell-two {
                display: flex;
                align-items: center;
                width: 20%;
            }

            // Delete
            .cell-three {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 10%;
                img {
                    height: 18px;
                    cursor: pointer;
                    &.edit-icon {
                        height: 15px;
                        margin-right: 10px;
                    }
                    &:hover {
                        // background-color: #FE9600;
                        // padding: 1rem;
                    }
                }
            }
        }
    }
    .bodyTable {
        height: calc(100% - 3rem);
        overflow: scroll;
    }
    .add-button-box {
        position: fixed;
        right: 1.5%;
        bottom: 2%;
        width: 4rem;
        height: 4rem;
        img {
            max-width: 100%;
            max-height: 100%;
            cursor: pointer;
        }
    }
}