
#manage-coupons{
    .ant-pagination-item.ant-pagination-item-active a{
		color: black !important;
		margin-left: 5px;
	}
    .ant-table-pagination.ant-pagination{
        float: none;
    }
    .coupon-list{
		width: 100%;
		border-right: 1px solid #eee;
		box-shadow: 3px 3px 8px -2px #eee;
		z-index: 11;
		height: 100%;
	}

    .category-list-wrapper {
		height: 90%;
		font-size: 13px;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 3rem;
		font-weight: 500;
		.circular-loader {
			margin-bottom: 10px;
		}
	}
}