.collection-dialog-title {
	text-align: center;
	font-size: 25px;
	font-weight: 700;
	padding: 10px 26px !important;
	border-bottom: 1px solid #e5e5e5;
	&.hideBorder {
		border-bottom: 0;
	}
}
.collection-dialog-subtitle {
	text-align: center;
	font-size: 15px;
	font-weight: 400;
	margin-top: 10px;
	margin-bottom: 0 !important;
}

.add-collection-content {
	padding: 0 !important;
}

.add-collection-dialog {
	.add-collection-display-body {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 1rem;
		img {
			max-width: 12rem;
			max-height: 12rem;
		}
		p {
			margin-top: 1rem;
		}
	}
	.add-collection-body {
		display: flex;
		width: 100%;
		align-items: center;
		padding: 1rem 15%;
		.form-control-wrap {
			display: flex;
			flex-direction: column;
			width: calc(100% - 10rem);
			margin-left: 1.5rem;
		}
		.upload-box {
			display: flex;
			flex-direction: column;
			width: 9rem;
			height: 9rem;
			justify-content: center;
			align-items: center;
			border: 1px solid #cdcdcd;
			border-radius: 3px;
			font-size: 13px;
			color: #fff;
			font-weight: 500;
			cursor: pointer;
			img.image-upload {
				margin-bottom: -10px;
				width: 50px;
			}
			img.media-thumb {
				max-width: 100%;
				max-height: 100%;
			}
		}
		.bg-white {
			background-color: #fff;
		}
		.form-control {
			display: flex;
			flex-direction: column;
			input {
				width: 100%;
			}
		}
		.form-control-row-wrap {
			display: flex;
			.form-control {
				flex: 1;
				&:first-of-type {
					margin-right: 1rem;
				}
			}
		}
	}
	.add-collection-list-body {
		display: flex;
		flex-direction: column;
		align-items: center;
		border-top: 1px solid #e5e5e5;
		.collection-dialog-subtitle {
			font-weight: 700;
		}
		.filter-artworks-wrapper {
			display: flex;
			flex-wrap: wrap;
			padding: 0 2%;
		}
	}
	.add-collection-filter-body {
		display: flex;
		flex-direction: column;
		align-items: center;
		border-top: 1px solid #e5e5e5;
		padding: 2rem 1rem 70px;
		.search-input {
			border: 1px solid #e5e5e5;
			border-radius: 0px;
			height: 45px;
			padding: 0px 10px;
			font-size: 14px;
			font-weight: 500;
			color: #323643;
			outline: none;
			width: 50%;
			background: url("../../../assets/images/common/Search.svg") no-repeat 96% 50%;
			background-size: 25px;
			padding-right: 6%;
			&::placeholder {
				color: #bbbbbb;
				font-size: 13px;
				font-weight: 400;
			}
			&:focus,
			&:active {
				border: 2px solid #58514b;
			}
		}
		.filter-wrapper {
			display: flex;
			width: 100%;
			margin-top: 1rem;
			margin-bottom: 1rem;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
		}

		.filter-artworks-searchLoader {
			display: flex;
			align-items: center;
			.search-loader {
				width: 15px !important;
				height: 15px !important;
				color: #323643;
				margin-right: 5px;
			}
		}
		.filter-artworks-wrapper {
			display: flex;
			flex-wrap: wrap;
			width: 100%;
			&.filterWrapper-empty {
				justify-content: center;
			}
			&.filterWrapper-loader {
				opacity: 0.5;
			}
		}

		.sort-dropdown {
			margin: 0 3px 3px 3px;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #58514b;
			.ant-select-selection {
				background-color: #58514b;
				border: none !important;
			}
			.ant-select-arrow {
				color: #bbbbbb;
				.anticon {
					margin-left: 5px;
				}
			}
			& .ant-select-selection .ant-select-selection__rendered .ant-select-selection-selected-value {
				color: #ffffff;
				margin-right: 5px;
			}
			&-label {
				font-size: 14px;
				color: #bbbbbb;
				padding: 0 0 0 14px;
				font-weight: 500;
			}
		}
		.ant-pagination {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 2rem;
			margin-bottom: 20px;
		}
		.ant-pagination-prev {
			min-width: 22px;
			height: 22px;
			line-height: 22px;
		}
		.ant-pagination-item {
			min-width: 22px;
			height: 22px;
			line-height: 22px;
			border-color: #eeeeee;
			border-radius: 0;
		}
		.ant-pagination-next {
			min-width: 22px;
			height: 22px;
			line-height: 22px;
		}
		.ant-pagination-jump-next {
			min-width: 22px;
			height: 22px;
			line-height: 22px;
		}
		.ant-pagination-jump-prev {
			min-width: 22px;
			height: 22px;
			line-height: 22px;
		}
		.ant-pagination-item a {
			color: #000000;
		}
		.ant-pagination-item:focus a,
		.ant-pagination-item:hover a {
			color: #58514b;
		}
		.ant-pagination-item-active:focus a,
		.ant-pagination-item-active:hover a,
		.ant-pagination-item.ant-pagination-item-active a {
			border-color: #58514b;
			color: #ffffff;
		}
		.ant-pagination-item-active {
			background-color: #58514b;
		}
		.ant-pagination-item:focus,
		.ant-pagination-item:hover {
			border-color: #58514b;
		}
		.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis {
			color: #58514b;
		}
		.anticon {
			color: #58514b;
		}
		.ant-pagination-prev .ant-pagination-item-link {
			border-color: #eeeeee;
			border-radius: 0;
		}
		.ant-pagination-next .ant-pagination-item-link {
			border-color: #eeeeee;
			border-radius: 0;
		}
		.ant-pagination-prev:focus .ant-pagination-item-link,
		.ant-pagination-next:focus .ant-pagination-item-link,
		.ant-pagination-prev:hover .ant-pagination-item-link,
		.ant-pagination-next:hover .ant-pagination-item-link {
			border-color: #58514b;
		}
		.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
		.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
			color: #58514b;
		}
	}
}

.add-collection-footer-container {
	position: absolute;
	bottom: 0;
	background-color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	box-shadow: 0 0 0 2px rgba(255, 0, 0, 0);
	height: 60px;
	border-top: 1px solid #e5e5e5;
	p {
		margin: 0;
	}
	button {
		border: 0;
		margin-left: 1rem;
		padding: 5px 2rem;
		height: 35px;
	}
}

.sort-dropdown-select {
	.ant-select-arrow {
		display: inline-block;
		top: 50%;
		left: unset;
	}
	.ant-select-selection {
		min-height: 45px;
		border-radius: 0;
		.ant-select-selection__rendered {
			height: 100%;
			.ant-select-selection-selected-value {
				padding-top: 8px;
				font-weight: 500;
				color: #323643;
				font-size: 14px;
			}
		}
	}
	.ant-select-selection--multiple .ant-select-selection__placeholder {
		margin-left: 6px;
		height: 100%;
		line-height: 34px;
	}
	.ant-select-selection--multiple {
		padding-bottom: 0;
	}
	.ant-select-selection:hover {
		border-color: #e5e5e5;
	}
	&.ant-select-open .ant-select-selection,
	&.ant-select-focused .ant-select-selection {
		box-shadow: none;
		border-color: #e5e5e5;
		&:focus-within {
			border: 2px solid #58514b;
			border-right-width: 2px !important;
		}
	}
	.ant-select-selection:focus,
	.ant-select-selection:active {
		box-shadow: none;
		border: 1px solid #e5e5e5;
		border-right-width: 3px !important;
		&:focus,
		&:active {
			border: 2px solid #58514b;
		}
	}
}

.sort-dropdown-select-options {
	z-index: 1300 !important;
}
