.ant-modal {
  width: 25rem !important;
}

.gallery-artist-profile-card {
  width: 300px;
  display: flex;
  flex-direction: column;
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  box-shadow: 3px 3px 8px 1px rgba(0, 0, 0, 0.2);
  transform: matrix(1, 0, 0, 1, 0, 0);
  padding: 20px 0px;
  align-items: center;
  justify-content: center;
  .profile-image {
    border: 0.5px solid #000000;
    border-radius: 50px;
    width: 100px;
    height: 100px;
  }

  .artist-name {
    font: normal normal 400 24px/26px Lato !important;
    color: #000000;
    margin-top: 5px;
  }
  .place-text {
    font: normal normal 400 14px/18px Lato !important;
    color: #000000;
  }
  .artist-types {
    margin: 15px 0px;
    text-align: center;
    width: 80%;
    span {
      font: normal normal 400 14px/18px Lato !important;
      color: #000000;
      opacity: 0.6;
    }
  }
  .follwers-count-container {
    display: flex;
    flex-direction: row;
    margin: 20px 0px 25px 0px;
    .count-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 10px;
      p {
        margin: 2px 0px !important;
        font: normal normal 800 18px/120% Lato !important;
        /* or 19px */
        text-align: center;
        color: #000000;
      }
      span {
        font: normal normal 400 14px/120% Lato !important;
      }
    }
  }
  .follow-btn {
    height: 40px;
    width: 250px;
    border: 0.5px solid #0a59ce;
    background-color: #0a59ce;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    span {
      font-style: normal !important;
      font-weight: 700 !important;
      font-size: 14px !important;
      line-height: 17 !important;
      /* or 14px */
      text-align: center;
      // text-transform: uppercase;
      color: #f8f8f8;
      padding: 5px 10px;
    }
  }
  .unfollow-btn {
    height: 40px;
    width: 250px;
    background: rgba(10, 89, 206, 0.1);
    border: 1px solid #0062e0;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    span {
      font-style: normal !important;
      font-weight: 700 !important;
      font-size: 14px !important;
      line-height: 17px !important;
      /* or 14px */
      text-align: center;
      // text-transform: uppercase;
      color: #0a59ce;
      padding: 5px 10px;
    }
  }
}

.artist-action-btn-section {
  width: 100%;
  margin-top: 40px;
  p {
    font: normal normal 400 14px/22px Lato !important;
  }
  .hr-line {
    width: 100%;
    height: 1px;
    border: 1px solid rgba(0, 0, 0, 0.6);
  }
  .action-btn-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    div {
      height: 30px;
      width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .send-btn {
      background-color: #000000;
      span {
        color: #fff;
      }
    }
  }
}

//new gallery artist
.create-new-gallery-artist-page {
  .gallery-form-wrapper-container {
    .gallery-form-flex-row {
      .form-label {
        font: normal normal 400 16px/20px Lato !important;
      }
      display: flex;
      flex-direction: row;
      .form-wrapper-first-row {
        width: 50%;
        margin-right: 50px;
        .living-artist-section {
          margin-top: 50px;
          .living-artist-checkbox {
            display: flex;
            flex-direction: row;
            margin-top: 15px;
            .form-check-label {
              font: normal normal 400 16px/20px Lato !important;
            }
            .form-check {
              margin: 0px 5px;
            }
          }
        }
      }
      .form-wrapper-second-row {
        width: 50%;
        .career-highlight-add-btn-wrapper {
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
          .career-highlight-btn {
            width: 34px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #000000;
            cursor: pointer;
            span {
              color: #fff;
              font: normal normal 400 16px/20px Lato !important;
            }
          }
        }
        .career-highlight-list {
          margin-top: 25px;
          .highlight-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-content: flex-start;
            width: 100%;
            .highlight-items {
              display: flex;
              p {
                width: 100%;
                border: 1px solid #e5e5e5;
                height: 30px;
                padding: 5px 10px;
                font: normal normal 400 16px/20px Lato !important;
              }
              .highlight-delete-btn {
                border: 1px solid black;
                background: black 0% 0% no-repeat;
                border: 1px solid black;
                opacity: 1;
                width: 35px;
                height: 30px;
                text-align: center;
                font: normal normal 400 16px/20px Lato !important;
                letter-spacing: 0.45px;
                color: #ffffff;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }
      }
    }
  }
}

.manage-artist-table-action-wrapper {
  display: flex;
  flex-direction: column;
  .action-item {
    display: flex;
    align-items: center;
    height: 45px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    cursor: pointer;
  }
}

.contact-artist-modal-wrapper {
  .anticon svg {
    color: red;
  }
  .contact-main-container {
    .heade-title-wrapper {
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid #000000;
      p {
        font: normal normal 700 16px/20px Lato !important;
      }
    }
    .contact-artist-content-wrapper {
      margin-top: 15px;
      .form-group {
        .form-label {
          font: normal normal 400 14px/18px Lato !important;
          margin-bottom: 5px;
        }
        .form-control {
          border: 0.5px solid rgba(0, 0, 0, 0.6);
          border-radius: 0px;
        }
        margin-bottom: 25px;
      }
      .action-btn-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        div {
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 120px;
          cursor: pointer;
        }
        .send-btn {
          background-color: #000000;
          span {
            color: #fff;
          }
        }
      }
    }
  }
}
