.lookup-wrapper {
	display: flex;
	height: calc(100% - 4rem);
	.lookup-main-list {
		width: 30%;
		border-right: 1px solid #eee;
		box-shadow: 3px 3px 8px -2px #eee;
		z-index: 11;
		height: 100%;
	}
	.ant-pagination-item.ant-pagination-item-active a{
		color: black !important;
		margin-left: 5px;
	}
	.lookup-sub-list{
		width: 70%;
		border-right: 1px solid #eee;
		box-shadow: 3px 3px 8px -2px #eee;
		z-index: 11;
		height: 100%;
	}
	div.category-list-wrapper {
		height: 90%;
		font-size: 13px;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 3rem;
		font-weight: 500;
		.circular-loader {
			margin-bottom: 10px;
		}
	}
	.list-head {
		display: flex;
		height: 2.5rem;
		.list-item {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #e1e1e1;
			font-weight: 500;
			color: #000000;
			cursor: pointer;
		}
		.active {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			font-weight: 500;
			background-color: #58514b;
			color: #ffffff;
		}
	}

	ul {
		list-style: none;
		width: 100%;
		margin: 0;
		padding: 0;
		height: 90%;
		overflow-y: scroll;
		&::-webkit-scrollbar {
			-webkit-appearance: none;
		}
		&::-webkit-scrollbar:vertical {
			width: 5px;
		}
		&::-webkit-scrollbar:horizontal {
			height: 0px;
		}
		&::-webkit-scrollbar-thumb {
			background-color: rgb(252, 233, 209);
			border-radius: 5px;
			border: 2px solid transparent !important;
		}
		&::-webkit-scrollbar-track {
			border-radius: 50%;
			background-color: transparent !important;
		}
		li {
			display: flex;
			align-items: center;
			height: 2.5rem;
			color: #000000;
			// border: solid 1px;
			font-size: 13px;
			font-weight: 500;
			
			// border-left: 3px solid transparent;
			cursor: pointer;
			.list-item-content {
				display: flex;
				align-items: center;
				flex: 3;
				padding: 0 0 0 1rem;
				height: 100%;
				img {
					width: 22px;
					height: 22px;
					object-fit: contain;
					margin-right: 15px;
					padding: 2px 1px;
					background-color: #eee;
					border: 1px solid #dcdcdc;
					border-radius: 2px;
				}
				p {
					margin: 0;
					color: #000000;
					font-size: 13px;
					font-weight: 500;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					width: 12rem;
				}
			}
			.list-item-action {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0 0.5rem 0 0rem;
				flex: 1;
				height: 100%;
				img.more-icon {
					display: block;
					width: 22px;
				}
				.edit-options {
					display: none;
				}
			}
			&.active {
				background-color: #f2d6a0;
				border-left: 3px solid #d2502d;
				transition: all 0.3s, height 0s;
				font-weight: 500;
				.list-item-action {
					img.more-icon {
						display: none;
					}
					.edit-options {
						display: flex;
						width: 100%;
						height: 100%;
						.image-wrapper {
							display: flex;
							align-items: center;
							justify-content: center;
							flex: 1;
							height: 100%;
							img {
								width: 20px;
							}
							&:hover {
								background-color: #58514b;
							}
						}
					}
				}
			}
			&:hover {
				background-color: #f2d6a0;
				border-left: 3px solid #d2502d;
				transition: all 0.3s, height 0s;
				font-weight: 500;
				.list-item-action {
					img.more-icon {
						display: none;
					}
					.edit-options {
						display: flex;
						width: 100%;
						height: 100%;
						.image-wrapper {
							display: flex;
							align-items: center;
							justify-content: center;
							flex: 1;
							height: 100%;
							img {
								width: 20px;
							}
							&:hover {
								background-color: #58514b;
							}
						}
					}
				}
			}
		}
	}
	.add-btn-fixed{
		position: fixed !important;
		bottom: 20px;
		right: 100px;
		z-index: 999;
	}
	.add-button-box {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		height: 45px;
		position: unset;
		.add-btn {
			border: 1px solid #58514b;
			border-radius: 3px;
			height: 35px;
			border: 1px solid #58514b;
			display: flex;
			align-items: center;
			margin-right: 20px;
			padding-left: 10px;
			padding-right: 10px;
			box-shadow: 0.25px 0.25px 12px #00000029;
			cursor: pointer;
			color: #58514b;
			font-size: 12px;
			p {
				font-size: 20px;
				color: #58514b;
				margin: 0;
				padding-right: 5px;
			}
		}
	}
}
