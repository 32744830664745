.add-shipment-provider-dialog {
	.add-shipment-provider-content {
		width: 25rem;
		font-size: 15px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		.add-shipment-provider-body {
			display: flex;
			flex-direction: column;
			width: 100%;
			align-items: center;
			// margin-bottom: 1rem;
			.upload-box {
				display: flex;
				background-color: #58514b;
				flex-direction: column;
				width: 9rem;
				height: 9rem;
				justify-content: center;
				align-items: center;
				border: 1px solid #cdcdcd;
				border-radius: 3px;
				margin-bottom: 1rem;
				font-size: 13px;
				color: #fff;
				font-weight: 500;
				cursor: pointer;
				img.image-upload {
					margin-bottom: 10px;
				}
				img.media-thumb {
					max-width: 9rem;
					max-height: 9rem;
					object-fit: fill;
					min-height: 9rem;
					min-width: 9rem;
				}
			}
			.bg-white {
				background-color: #fff;
			}
			.form-control {
				width: 100%;
				display: flex;
				flex-direction: column;
				input {
					width: 100%;
				}
			}
		}
		.ant-select-selection:focus,
		.ant-select-selection:active,
		.ant-select-selection:hover {
			border-color: #000000;
			-webkit-box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2);
			box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2);
		}
		.add-shipment-provider-featured-box {
			display: flex;
			width: 100%;
			margin-bottom: 1rem;
			justify-content: space-between;
			font-size: 14px;
			font-weight: 500;
			color: rgba(0, 0, 0, 0.85);
			padding: 0 5px;
			.ant-checkbox-inner {
				border-color: #707070;
			}
			.ant-checkbox-checked .ant-checkbox-inner {
				background-color: #fe9600;
			}
		}
		.add-shipment-provider-action {
			display: flex;
			width: 100%;
			justify-content: center;
			button {
				width: 49%;
				&:first-of-type {
					margin-right: 2%;
				}
			}
			.modal-button-loader {
				position: absolute;
				left: 70%;
			}
		}
	}
}
