.forgot-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #F5F5F5;
    height: 100%;
    .app-logo{
        display: flex;
        justify-content: center;
        padding-bottom: 30px;
    }
    .forgot-contents{
        display: flex;
        flex-direction: column;
        width: 24rem;
        height: 17rem;
        padding-bottom: 40px;
        background-color: #FFFFFF;
        padding: 2rem 2.5rem 2rem;
        @media screen and (max-width: 430px) {
            width: 80%;
        }
        .page-title {
            font-size: 16px;
            color: #434343;
            margin: 0;
            text-align: center;
            font-weight: 700;
            padding-bottom: 2rem;
        }
        .form-control {
            width: 100%;
            display: flex;
            flex-direction: column;
            input {
                width: 100%;
                margin-bottom: 25px;
            }
        }
        .end-btn{
            display: flex;
            button{
                height: 45px;
                width: 100%;
                position: relative;

            }
        }
    }
    .otp-progress {
        position: absolute;
        top: 20%;
        left: 46%;
        color: #FE9600;
    }
}