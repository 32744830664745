.colWrapper {
    display: flex;
}

.colImage {
    height: 70px;
    width: 70px;
    object-fit: cover;
}
.Pending {
    color: yellowgreen;
    font-weight: bold;
}
.Order {
    color: rgb(223, 80, 14);
    font-weight: bold;
}

.Packed {
    color: rgb(156, 105, 10);
    font-weight: bold;
}

.Collected {
    color: rebeccapurple;
    font-weight: bold;
}

.Shipped {
    color: darkcyan;
    font-weight: bold;
}

.Delivered {
    color: green;
    font-weight: bold;
}

.Returned {
    color: blue;
    font-weight: bold;
}

.Cancelled {
    color: red;
    font-weight: bold;
}

.overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 250px !important;
}

.main {
    height: 100%;
    padding-bottom: 10vh;

    ::-webkit-scrollbar {
        display: none;
    }
}
